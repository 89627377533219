import React from 'react';
import Tumbleweed from './authenticate/tumbleweed';

export const Hub404 = () => (
  <div className="">
    <Tumbleweed messageKey="hub404" />
  </div>
);
export const Project404 = () => (
  <div className="mt-5">
    <Tumbleweed messageKey="project404" />
  </div>
);
