import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import t from '../../t';

const ActivitiesFeed = () => {
  const { projectStore } = useStore();

  const [activitiesFetched, setActivitiesFetched] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    projectStore.activeProject?.myProjectHelper
      ?.fetchActivities()
      .then(() => {
        setActivitiesFetched(true);
      })
      .catch(error => {
        console.error(t('activities.error'), error);
      });
  }, []);
  return (
    <>
      <Card className="h-auto asks-feed">
        <CardHeader className="d-flex justify-content-between ">
          <div>
            <FontAwesomeIcon className="mr-2" icon="bell" />
            <strong>{t('header.notifications')}</strong>
          </div>
        </CardHeader>
        <CardBody>
          {!activitiesFetched && (
            <p className="text-muted small">{t('activities.loading')}</p>
          )}
          {activitiesFetched && (
            <div className="table-responsive">
              <Table hover>
                <tbody>
                  {isEmpty(
                    projectStore.activeProject?.myProjectHelper?.activities
                  ) && (
                    <tr>
                      <td>
                        <p className="text-muted">{t('activities.empty')}</p>
                      </td>
                    </tr>
                  )}
                  {projectStore.activeProject?.myProjectHelper?.activities.map(
                    (activity, i) => (
                      <tr key={i}>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(`activities.${activity.key}`, {
                                who: activity?.createdBy,
                                what: activity?.trackableName,
                                projectId: projectStore.activeProject?.id,
                                trackableId: activity?.trackableId,
                                commentableType: activity?.commentableType,
                              }),
                            }}
                          />{' '}
                          <br />
                          <span className="mt-1 small-12">
                            {moment(activity.createdAt).format(
                              'MMMM Do YYYY, h:mm a'
                            )}
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default observer(ActivitiesFeed);
