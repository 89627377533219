import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';

function InsightChart({ insight, title, description }) {
  useEffect(() => {
    insight.fetch();
  }, []);

  const periodOptions = [
    { label: 'This week', value: 'week' },
    { label: 'Last 30 Days', value: 'month' },
    { label: 'Last 3 Months', value: 'quarter' },
    { label: 'Last 12 Months', value: 'year' },
  ];

  const handlePeriod = period => {
    insight.update({
      selectedPeriod: period,
    });
    insight.fetch();
  };

  return (
    <div>
      <div className="chart-layout align-items-center d-flex justify-content-between">
        {!isEmpty(title) && (
          <div className="cl-left">
            <h3>{title}</h3>
            {!isEmpty(description) && <p className="mb-0">{description}</p>}
          </div>
        )}

        <div style={{ fontSize: 33, textAlign: 'center' }}>
          <FontAwesomeIcon icon={faChartLine} className="text-success" /> 55%
        </div>
      </div>
      <Row>
        <Col lg={3}>
          <div
            style={{
              border: '1px solid #f3f3f3',
              padding: 30,
              borderRadius: 15,
            }}
          >
            <div
              style={{
                fontSize: 14,
                borderRadius: 10,
                marginTop: 30,
              }}
            >
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever s
              </p>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using
              </p>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <div className="chart-layout">
            <div className="cl-heading">
              <div className="cl-right">
                <div>
                  {periodOptions.map(p => (
                    <Button
                      className="mr-3"
                      onClick={() => handlePeriod(p.value)}
                      key={p.value}
                      active={insight.selectedPeriod === p.value}
                    >
                      {p.label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="chart-load">
              <div>
                <Line
                  data={insight.chartData}
                  height={150}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                        max: 100,
                        title: {
                          display: true,
                          text: 'Score',
                        },
                      },
                    },
                  }}
                  // redraw={!chart.render}
                />
                {insight.fetching && (
                  <div className="cl-load">
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <h4>Loading</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

InsightChart.propTypes = {
  insight: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};
export default observer(InsightChart);
