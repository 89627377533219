import { filter, isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import useStore from '../../data/store';
import HelperDetailCondensed from './_helper_detail_condensed';
import AnnouncementBox from '../announcements/_announcement_box';

const HelpersCondensedFeed = ({ records, store }) => {
  const { projectStore, userStore } = useStore();
  const [selectedHelperIds, setSelectedHelperIds] = useState([]);
  const helpable = projectStore.activeProject || projectStore.account;

  const toggleHelperSelection = id => {
    if (selectedHelperIds.includes(id)) {
      setSelectedHelperIds(filter(selectedHelperIds, i => i !== id));
    } else {
      setSelectedHelperIds([...selectedHelperIds, id]);
    }
  };

  const selectAllHelpers = helpers => {
    if (selectedHelperIds.length === helpers.length) {
      setSelectedHelperIds([]);
    } else {
      setSelectedHelperIds(map(helpers, 'id'));
    }
  };

  return (
    <div className="list-view">
      <div className="text-right mb-3">
        <AnnouncementBox
          project={helpable}
          disabled={isEmpty(selectedHelperIds)}
          announceableId={helpable.id}
          announceableType="Helpable"
          projectHelperIds={selectedHelperIds}
          announcementType="direct"
          onAnnouncement={() => {
            setSelectedHelperIds([]);
          }}
        />
      </div>
      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedHelperIds.length === records.length}
                  onChange={() => selectAllHelpers(records)}
                />
              </th>
              <th>Helper</th>
            </tr>
          </thead>
          <tbody>
            {map(records, (record, i) => (
              <tr key={i}>
                <td>
                  {record.userId !== userStore.currentUser.id && (
                    <input
                      type="checkbox"
                      checked={selectedHelperIds.includes(record.id)}
                      onChange={() => toggleHelperSelection(record.id)}
                    />
                  )}
                </td>
                <td>
                  <HelperDetailCondensed helper={record} key={i} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

HelpersCondensedFeed.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
};

export default observer(HelpersCondensedFeed);
