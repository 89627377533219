import axios from 'axios';

const axiosObj = () => {
  const instance = axios.create({
    headers: {
      'cache-control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  });

  return instance;
};

export default axiosObj();
