import { isFunction, startCase } from 'lodash';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

function ProjectNeed({ onEdit, need, onDelete }) {
  const deletable = isFunction(onDelete);

  return (
    <div className="badge">
      <span
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={onEdit}
      >
        {startCase(need.title)}
      </span>
      {deletable && need.persisted && (
        <Button
          onClick={() =>
            confirmAlert({
              title: 'Change Status',
              message: need.isActive
                ? 'Do you want to pause this need. This will result in pause of all the asks and announcements in this need. Users following this need will be notified for this action'
                : 'Do you want to unpause this need?. Users following this need will be notified for this action',
              buttons: [
                {
                  label: 'Confirm',
                  onClick: () => need.toggleStatus(),
                },
                {
                  label: 'Cancel',
                },
              ],
            })
          }
        >
          <FontAwesomeIcon
            icon={need.isActive ? faToggleOn : faToggleOff}
            size="xl"
          />
        </Button>
      )}
      {deletable && (
        <Button onClick={() => onDelete(need)}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
      )}
      {/* <UncontrolledTooltip target={`need_${index}`}> */}
      {/*  {need.description} */}
      {/* </UncontrolledTooltip> */}
    </div>
  );
}

ProjectNeed.propTypes = {
  onEdit: PropTypes.func,
  need: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func,
};
export default observer(ProjectNeed);
