import { action, computed, makeObservable, observable, override } from 'mobx';
import { confirmAlert } from 'react-confirm-alert';
import BaseEntity from './base_entity';

class Comment extends BaseEntity {
  @observable projectHelper = {};

  @observable body = '';

  @observable saving = false;

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
  }

  @computed
  get isItMe() {
    return this.projectHelper.user_id === this.currentUser.id;
  }

  @action
  create() {
    if (this.saving) return;

    this.update({ saving: true });

    return this.client
      .post(`/api/v1/comments`, {
        ...this.store.commentable,
        body: this.body,
      })
      .then(res => {
        this.store.addRecord(res.data.comment);
        this.update({ saving: false, body: '' });
      })
      .catch(() => {
        this.saving = false;
      });
  }

  @override
  destroy() {
    confirmAlert({
      title: 'Delete Comment',
      message: 'Are you sure you want to delete this comment?',
      buttons: [
        {
          label: 'Delete',
          onClick: () => {
            this.client.delete(`/api/v1/comments/${this.id}`).then(res => {
              this.store.records.splice(this.store.records.indexOf(this));
            });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  }
}

export default Comment;
