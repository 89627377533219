import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import useStore from '../../data/store';
import t from '../../t';
import { HelpableBadge } from '../settings/settings';

const Hubs = () => {
  const { hubStore } = useStore();

  useEffect(() => {
    hubStore.fetch();
  }, []);

  const consoleRecord = record => {
    console.log(record.createdBy.name);
    return '';
  };
  return (
    <>
      <div className="mb-3 text-right">
        <Button to="/onboard" tag={Link} color="primary">
          {t('header.create_hub')}
        </Button>
      </div>
      <Card className="rounded-lg overflow-hidden">
        <CardBody style={{ background: '#f3f3f3' }}>
          {map(hubStore.records, (record, index) => (
            <Card>
              <CardHeader className="d-flex">
                <div className="ml-3 mr-3">
                  <HelpableBadge helpable={record} />
                </div>
                <div>
                  <a target="_blank" href={record.url} rel="noreferrer">
                    {record.title}
                  </a>
                </div>
              </CardHeader>

              <CardBody className="d-flex"></CardBody>
            </Card>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default observer(Hubs);
