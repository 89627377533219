import { isEmpty, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactTimeAgo from 'react-time-ago';
import { Badge, Col, Row, Table } from 'reactstrap';
import Pluralize from 'react-pluralize';

const EntryList = ({ records }) => (
  <Col>
    <div className="table-responsive">
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>From</th>
            <th>To</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Context</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {map(records, (record, i) => (
            <tr key={i}>
              <td>
                <ReactTimeAgo date={new Date(record.createdAt)} />
              </td>
              <td>{record.from}</td>
              <td>{record.to}</td>
              <td>{record.notes}</td>
              <td className={record.textClass}>
                <Pluralize
                  singular="token"
                  count={record.isDebit ? -1 * record.amount : record.amount}
                />{' '}
              </td>
              <td>{record.context}</td>
              <td>
                {' '}
                <Badge color={record.statusClass}>{record.status}</Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </Col>
);

EntryList.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
};

export default observer(EntryList);
