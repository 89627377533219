import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import t from '../../t';

function RejectProject({ project }) {
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('criteria_mismatch');

  return (
    <>
      <Button onClick={() => setOpen(true)} color="danger">
        {t('common.reject')}
      </Button>
      <Modal backdrop="static" isOpen={open}>
        <ModalHeader>Reject Confirmation</ModalHeader>
        <ModalBody>
          <div className="rejection-list">
            {['criteria_mismatch', 'unacceptable_video'].map(reason => (
              <Button
                key={reason}
                onClick={() => setRejectReason(reason)}
                className={classnames('rejection-reason', {
                  active: reason === rejectReason,
                })}
              >
                <h4>{t(`projects.rejection.types.${reason}.label`)}</h4>
                <div>{t(`projects.rejection.types.${reason}.description`)}</div>
              </Button>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpen(false)}>{t('common.cancel')}</Button>
          <Button
            onClick={() => project.rejectProject(rejectReason)}
            color="danger"
          >
            {t('common.reject')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

RejectProject.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired,
};

export default RejectProject;
