import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { find, isEmpty, reject } from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import ProjectNeed from '../../data/entities/project_need';
import ProjectNeedComponent from './_project_need';
import HintedLabel from '../shared/_hinted_label';
import useStore from '../../data/store';
import { filterSelectStyles } from '../../helpers/sidebar_helpers';

const EditProjectNeeds = ({ project, className = '' }) => {
  const { appStore } = useStore();

  const [activeProjectNeed, setActiveProjectNeed] = useState(null);
  const descriptionInputRef = useRef();
  const [needDeletable, setNeedDeletable] = useState(false);
  const [params, setParams] = useState({
    title: '',
    description: '',
    system_need_id: '',
  });

  const setNeedError = (field, message) => {
    alert(message);
    document.getElementById(`project-need-${field}`).focus();
  };

  const validateNeeds = () => {
    if (isEmpty(activeProjectNeed.title.trim())) {
      setNeedError('title', 'Please enter a title for your need.');
      return false;
    }
    if (isEmpty(activeProjectNeed.description.trim())) {
      setNeedError(
        'description',
        'Please enter a short description for your need.'
      );
      return false;
    }
    if (project.projectNeeds.includes(activeProjectNeed)) {
      setNeedError(
        'title',
        'You have already added that need. Delete it first to re-add it.'
      );
      return false;
    }
    return true;
  };

  const handleNeedSubmit = e => {
    if (isEmpty(activeProjectNeed)) return;

    activeProjectNeed.update(params);

    if (!validateNeeds()) return;
    if (!needDeletable) project.addNeed(activeProjectNeed);
    setActiveProjectNeed(null);
    setNeedDeletable(false);
    setParams({ title: '', description: '', system_need_id: '' });
  };

  const handleDeleteNeed = need => {
    if (need.persisted) {
      return confirmAlert({
        title:
          'Are you sure you want to delete this need?. This cannot be undone if you save the project.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              need.update({ setForDelete: true });
              setNeedDeletable(false);
            },
          },
          {
            label: 'Cancel',
          },
        ],
      });
    }

    project.removeNeed(need);

    setNeedDeletable(false);
  };

  const submitOnReturn = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNeedSubmit(e);
    }
  };

  return (
    <div className={`${className}`}>
      <FormGroup>
        <HintedLabel
          helpTextKey="add_needs"
          error={project.hasError('add_needs')}
        />
        <div className="d-flex">
          <Row className="flex-grow-1" onKeyDown={submitOnReturn}>
            <Col lg={5}>
              <Select
                options={reject(
                  appStore.systemNeedOptions,
                  need =>
                    !isEmpty(find(project.needs, { systemNeedId: need.value }))
                )}
                styles={filterSelectStyles}
                placeholder="Select Category"
                value={
                  activeProjectNeed?.title
                    ? {
                        label: activeProjectNeed.title,
                        value: activeProjectNeed.id,
                        description: activeProjectNeed.description,
                      }
                    : null
                }
                onChange={e => {
                  if (isEmpty(e.value)) return;
                  setActiveProjectNeed(
                    new ProjectNeed({
                      title: e.label,
                      description: e.description || '',
                      systemNeedId: e.value,
                    })
                  );

                  setParams({
                    title: e.label,
                    description: e.description || '',
                    systemNeedId: e.value,
                  });
                  descriptionInputRef.current.focus();
                }}
              />
            </Col>
            <Col lg={7}>
              <Input
                required
                placeholder="Add Description"
                id="project-need-description"
                value={params?.description || ''}
                disabled={isEmpty(activeProjectNeed)}
                onChange={e => {
                  setParams({ ...params, description: e.target.value });
                }}
                ref={descriptionInputRef}
              />
            </Col>
          </Row>
          {!isEmpty(activeProjectNeed) && (
            <Button
              className="ml-3"
              onClick={() => {
                setActiveProjectNeed(null);
                setParams({ title: '', description: '', system_need_id: '' });
              }}
            >
              Cancel
            </Button>
          )}
          {needDeletable ? (
            <Button
              onClick={() => {
                setActiveProjectNeed(null);
                activeProjectNeed.update(params);
                setParams({ title: '', description: '', system_need_id: '' });
                setNeedDeletable(false);
              }}
              type="button"
              color="primary ml-3 small"
            >
              Save
            </Button>
          ) : (
            <Button onClick={handleNeedSubmit} color="primary ml-3 small">
              <FontAwesomeIcon icon="plus-circle" />
            </Button>
          )}
        </div>
      </FormGroup>
      <FormGroup>
        <div className="add-need">
          {project.needs.map((need, index) => (
            <ProjectNeedComponent
              need={need}
              key={index}
              onEdit={() => {
                setActiveProjectNeed(need);
                setParams({
                  title: need.title,
                  description: need.description,
                  system_need_id: need.systemNeedId,
                });
                setNeedDeletable(true);
              }}
              onDelete={handleDeleteNeed}
            />
          ))}
        </div>
      </FormGroup>
    </div>
  );
};

EditProjectNeeds.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

export default observer(EditProjectNeeds);
