import PropTypes from 'prop-types';
import React from 'react';
import { isEqual } from 'lodash';
import { Redirect } from 'react-router-dom';
import Spacer from '../layouts/spacer';
import t from '../../t';
import WrappedHTML from '../shared/_wrapped_html';
import Player from '../modules/player';

function HelpShow({ match }) {
  const helpKey = match.params.id;
  const videoUrl = t(`help.${helpKey}.video`);
  const label = t(`help.${helpKey}.label`);
  const helpExists = !isEqual(label, `help.${helpKey}.label`);
  const hasVideo = !isEqual(videoUrl, `help.${helpKey}.video`);

  if (!helpExists) return <Redirect to="/" />;

  return (
    <div>
      <Spacer space={1} />
      <div className="centered w-90 hub-landing-page">
        <div className="p-1 text-center">
          <h1>{t(`help.${helpKey}.label`)}</h1>
        </div>
        {hasVideo && (
          <>
            <Spacer space={1} />
            <div className="w-75 centered p-0">
              <Player url={videoUrl} controls="playOnly" muted={false} />
            </div>
            <Spacer space={3} />
          </>
        )}
        <div className="p-1 text-left">
          <WrappedHTML
            content={t(`help.${helpKey}.text`)}
            className="mt-1 hub-description centered w-75"
          />
        </div>
      </div>
    </div>
  );
}

HelpShow.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default HelpShow;
