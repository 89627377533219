import React from 'react';
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTimeAgo from 'react-time-ago';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Pluralize from 'react-pluralize';
import { computed } from 'mobx';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment/moment';
import classnames from 'classnames';
import AskStatus from './_ask_status';
import AskActions from './_ask_actions';
import HintedLabel from '../shared/_hinted_label';
import t from '../../t';

const AskInfo = ({ ask, what }) => {
  const expiryStatus = () => {
    if (isEmpty(ask.expiresAt)) return 'No Expiry';
    if (moment(ask.expiresAt) < moment()) return 'Expired';

    return (
      <>
        Expires <ReactTimeAgo date={new Date(ask.expiresAt)} />
      </>
    );
  };

  const statusInfo = () => (
    <>
      <div className="ask-footer">
        <div className="ask-footer-left ">
          <Label>
            <FontAwesomeIcon icon={{ prefix: 'far', iconName: 'calendar' }} />{' '}
            Created <ReactTimeAgo date={new Date(ask.createdAt)} />
          </Label>
          <Label className="border-right-0">
            <FontAwesomeIcon icon="coins" />
            <Pluralize singular={ask.rewardType} count={ask.rewardAmount} />
          </Label>
          {ask.project?.canManage && (
            <Label className="border-right-0">
              <FontAwesomeIcon icon="users" />
              <Pluralize singular="helper" count={ask.maxHelpers} />
            </Label>
          )}
        </div>
        <div className="ask-footer-right">
          <AskActions ask={ask} />
        </div>
      </div>
    </>
  );

  const statusSummary = () => (
    <Card className="ask-status-card h-auto mb-2">
      <CardHeader>
        <CardTitle className="small">Working on this Ask</CardTitle>
      </CardHeader>
      <CardBody>
        <Alert
          color={classnames({
            info: isEqual(ask.status, 'todo'),
            warning: isEqual(ask.status, 'doing'),
            danger: isEqual(ask.status, 'expired'),
            success: isEqual(ask.status, 'done'),
          })}
        >
          <small>{t(`ask.status.${ask.status}.summary`)}</small>
        </Alert>
      </CardBody>
    </Card>
  );
  if (what === 'statusInfo') return statusInfo();
  if (what === 'statusSummary') return statusSummary();
  return null;
};

AskInfo.propTypes = {
  ask: PropTypes.instanceOf(Object).isRequired,
  what: PropTypes.string,
};

export default observer(AskInfo);
