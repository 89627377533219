import { action } from 'mobx';

import Pagination from '../entities/pagination';

const Filterable = {
  pagination: null,

  initializeFilterable: action(function(params) {
    this.pagination = new Pagination(
      {
        current: 1,
        params,
      },
      this
    );
  }),
};

export default Filterable;
