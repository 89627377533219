import moment from 'moment/moment';
import React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import useStore from '../../data/store';

const SiteFooter = () => {
  const { appStore } = useStore();

  return (
    <footer className="pb-3">
      {/*<div className="container-fluid">*/}
        <Container>
          <hr />
          <div className="d-flex justify-content-between">
            <div>
              {' '}
              <span>Version: {appStore.version}</span>
            </div>
            <div>
              Helpbuild is owned and operated with{' '}
              <span style={{ color: 'red' }}> &#9829;</span> by Helpbuild
              Network Limited © 2022 - {new Date().getFullYear()}. All rights
              reserved.
            </div>
            <div className="text-md-right footer-links d-none d-sm-block">
              <a
                target="_blank"
                href="https://helpbuild.co/privacy"
                rel="noreferrer"
              >
                Privacy
              </a>
            </div>
          </div>
        </Container>
      {/*</div>*/}
    </footer>
  );
};

export default observer(SiteFooter);
