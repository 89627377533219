import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import useStore from '../../data/store';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import Announcement from './_announcement';

const PublicAnnouncementDetailPage = ({ match }) => {
  const { announcementStore, userStore } = useStore();
  const [announcement, setAnnouncement] = useState();
  const history = useHistory();

  useEffect(() => {
    announcementStore
      .fetchAnnouncementFromEncodedId(match.params?.announcement_id)
      .then(a => {
        if (userStore.userSignedIn) {
          history.push(`/p/${a.projectId}/announcements/${a.id}`);
        }
        setAnnouncement(a);
      });
  }, []);

  if (isEmpty(announcement)) return null;

  return (
    <div>
      <div className="bl-content">
        <Row className="mb-3 project-dashboard">
          <Col lg={{ offset: 3, size: 6 }}>
            <Announcement announcement={announcement} hyperlink={false} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

PublicAnnouncementDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default observer(PublicAnnouncementDetailPage);
