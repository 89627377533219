import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  LineElement,
  Title,
} from 'chart.js';

import '../src/assets/style';

import EntryPoint from '../src/entry_point';

library.add(fas, fab, far);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

TimeAgo.addDefaultLocale(en);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <EntryPoint />,
    document.body.appendChild(document.createElement('div'))
  );
});
