import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import classnames from 'classnames';
import Header from './_header';
import SiteFooter from './site_footer';

const LayoutBody = ({ children, forcePublic, loading }) => (
  <div className={classnames('bootstrap-layout', {})}>
    <div className="bl-content">
      <Header forcePublic={forcePublic} />
      <Container className="flex-grow-1">
        <main>{loading ? 'Loading' : children}</main>
      </Container>
      <SiteFooter />
    </div>
  </div>
);

LayoutBody.defaultProps = {
  forcePublic: false,
  loading: false,
};

LayoutBody.propTypes = {
  children: PropTypes.node,
  forcePublic: PropTypes.bool,
  loading: PropTypes.bool,
};

export default observer(LayoutBody);
