import React from 'react';
import classnames from 'classnames';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';

const Checkbox = ({
  label,
  checked,
  disabled,
  onChange,
  className,
  icon,
  description,
}) => (
  <FormGroup
    check
    className={classnames(className, {
      checked,
    })}
  >
    <Label check>
      {!checked && icon && <FontAwesomeIcon icon={faSquare} size="2x" />}
      {checked && icon && <FontAwesomeIcon icon={faCheckSquare} size="2x" />}
      <Input
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        checked={checked}
      />
      <p className="pl-3">
        <span className="font-weight-bold">{label}</span>
        {description && (
          <small className="text-sm-left p-0 text-muted text-center condensed">
            <br />
            {description}
          </small>
        )}
      </p>
    </Label>
  </FormGroup>
);

Checkbox.defaultProps = {
  className: '',
  icon: false,
  description: '',
};

Checkbox.propTypes = {
  label: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.bool,
};

export default Checkbox;
