import { observer } from 'mobx-react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { startCase } from 'lodash';
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

function Need({ checked, toggle, need }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{ gap: 7 }}
      className={classnames('px-3 py-1 btn flex-direction-column', {
        'checked-need': checked,
      })}
    >
      <div className="d-flex align-items-center w-100">
        <div
          onClick={() => toggle(need.id)}
          style={{ flex: 1, gap: 5 }}
          className="d-flex align-items-center font-weight-bold"
        >
          <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} />
          {startCase(need.title)}
        </div>
        <FontAwesomeIcon
          icon={open ? faChevronCircleUp : faChevronCircleDown}
          onClick={() => setOpen(!open)}
        />
      </div>
      <Collapse isOpen={open}>
        <div className="text-left mt-3">{need.description}</div>
      </Collapse>
    </div>
  );
}

Need.propTypes = {
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  need: PropTypes.instanceOf(Object).isRequired,
};
export default observer(Need);
