import { action, computed, flow, makeObservable, observable } from 'mobx';
import { camelCase, includes, isEmpty, map, startCase } from 'lodash';
import moment from 'moment';
import client from '../axiosClient';

class Insight {
  @observable keys = [];

  @observable insightable;

  @observable selectedKeys = [];

  @observable selectedPeriod = 'week';

  @observable customPeriod = [];

  @observable insightableType;

  @observable data = [];

  @observable fetching = false;

  constructor(insightable, insightableType, keys) {
    this.insightableType = insightableType;
    this.insightable = insightable;
    this.keys = keys;
    this.client = client;

    makeObservable(this);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @computed
  get selectedData() {
    if (isEmpty(this.selectedKeys)) return this.data;

    return filter(this.data, d => includes(this.selectedKeys, d.insight_key));
  }

  @computed
  get labels() {
    const singleData = this.data[0];
    if (isEmpty(singleData)) return [];
    if (isEmpty(singleData.data)) return [];

    return singleData.data.map(d => {
      const data = moment(d.insight_date);

      return data.format('MMM DD, YYYY');
    });
  }

  @computed
  get chartData() {
    return {
      labels: this.labels,
      datasets: this.selectedData.map(data => ({
        label: startCase(data.insight_key),
        data: data.data.map(d => d.score),
      })),
    };
  }

  @flow
  *fetch() {
    if (this.fetching) return;

    this.fetching = true;

    try {
      const response = yield this.client.get(`/api/v1/insights`, {
        params: {
          custom_period: this.customPeriod,
          insight_period: this.selectedPeriod,
          insight_keys: this.keys,
          insightable_id: this.insightable.id,
          insightable_type: this.insightableType,
        },
      });

      this.data = response.data;
      this.fetching = false;
    } catch (e) {
      this.fetching = false;
    }
  }
}

export default Insight;
