import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Card, CardBody } from 'reactstrap';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import FilteredList from '../modules/filtered_list';
import { walletFilters } from '../../helpers/filter_helpers';
import EntryList from './_entry_list';
import WalletEntryStore from '../../data/stores/wallet_entry_store';
import Wallet from '../../data/entities/wallet';
import BuyTokens from '../shared/buy_tokens';

const WalletTransactions = ({ walletable, disableHeader }) => {
  const { walletEntryStore } = useStore();
  console.log(walletable);
  walletable.wallet.store.wallet_id = walletable.wallet.id;

  useEffect(() => {
    if (isEmpty(walletable.wallet)) {
      walletable.update({
        wallet: new Wallet({}, walletEntryStore),
      });
    }
    walletable.wallet.store.wallet_id = walletable.wallet.id;
  }, []);

  if (isEmpty(walletable.wallet)) return null;

  return (
    <>
      <Card className="tour-wallet">
        <CardBody>
          {!disableHeader && (
            <>
              <h4 className="mb-4">
                <strong>Wallets</strong>
              </h4>
              <hr />
            </>
          )}
          <div className="mb-4 text-right">
            <BuyTokens wallet={walletable.wallet} className="buy-token-btn" />
          </div>
          <div className="d-flex justify-content-between mb-4 border-bottom pb-1 tour-balances">
            <div>Operating Balance: {walletable.wallet.balance}</div>
            <div>Reserved Balance: {walletable.wallet.reserved}</div>
            <div>Pending Balance: {walletable.wallet.pending}</div>
          </div>
          <FilteredList
            store={walletable.wallet.store}
            filters={walletFilters()}
            listComponent={EntryList}
            recordClassName="col-xl-4"
            listClassName="tour-wallet-entries"
            autoFetch={false}
            changeTrigger=""
          />
        </CardBody>
      </Card>
    </>
  );
};

WalletTransactions.defaultProps = {
  disableHeader: false,
};

WalletTransactions.propTypes = {
  walletable: PropTypes.instanceOf(Object).isRequired,
  disableHeader: PropTypes.bool,
};
export default observer(WalletTransactions);
