import { computed, flow, makeObservable, observable } from 'mobx';

import { isEmpty, isFunction } from 'lodash';
import BaseStore from './base_store';
import User from '../entities/user';

class UserStore extends BaseStore {
  /* eslint-disable */
  @observable currentUser = {};
  @observable currentAccount = {};
  @observable fetchingCurrentUser = true;
  @observable users = [];
  @observable pagination = {};

  /* eslint-enable */

  constructor(store) {
    super(store, User);

    makeObservable(this);
  }

  @computed
  get userSignedIn() {
    return !isEmpty(this.currentUser);
  }

  @computed
  get showGeneralOnBoarding() {
    return !this.rootStore.userStore.currentUser.generalOnboarded;
  }

  @flow
  *fetchCurrentUser(onFetch) {
    const response = yield this.client.get('/api/v1/users/auth.json');

    if (response.data.success) {
      this.update({
        currentUser: new User(response.data.user, this),
      });

      if (isFunction(onFetch)) onFetch();
    }

    this.fetchingCurrentUser = false;
  }
}

export default UserStore;
