import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import useStore from '../../data/store';
import ShareDetail from '../share/detail';
import HubDashboard from '../dashboard/hub';

function AccountShare({ match }) {
  const { projectStore } = useStore();
  const { account } = projectStore;

  useEffect(() => {
    if (isEmpty(account)) return;

    account.fetchShares();
  }, [account?.id]);

  if (isEmpty(account)) return null;

  const share = find(account.shares, { id: match.params?.share_id });

  if (isEmpty(share)) return null;

  return (
    <div>
      <HubDashboard />
      <ShareDetail share={share} />
    </div>
  );
}

AccountShare.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};
export default observer(AccountShare);
