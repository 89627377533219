import React from 'react';
import { observer } from 'mobx-react';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import AsksIndex from './index';
import withProject from '../layouts/with_project';
import useStore from '../../data/store';
import { redirectToAuth } from '../../routes/AuthenticatedRoute';

const AsksPage = () => {
  const { userStore } = useStore();

  if (!userStore.userSignedIn) {
    redirectToAuth();
    return null;
  }

  return (
    <div>
      <ThreeColumnLayout
        center={<AsksIndex />}
        right={<HelpersLeaderboard />}
      />
    </div>
  );
};

export default withProject(observer(AsksPage));
