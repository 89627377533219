import React, { useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, map } from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import Linkify from 'react-linkify';
import Helpers from './_ask_helpers';
import Info from './_info';
import AskInfo from './_ask_info';
import RichEditor from '../modules/rich_text_editor';
import t from '../../t';
import { basicEditorToolbar } from '../../helpers/shared_helpers';
import Comments from '../shared/comments';
import AskStatus from './_ask_status';

const AskDetail = ({ ask, extraClassNames = '', basic = false }) => {
  const [tab, setActiveTab] = useState(0);
  const history = useHistory();

  if (isEmpty(ask)) return null;

  const tabs = [];

  tabs.push({
    icon: 'info-circle',
    label: 'Ask Details',
    component: <Info ask={ask} />,
  });
  if (ask?.project?.userPermissions.includes('manage_helpers')) {
    tabs.push({
      icon: 'users',
      label: 'Helpers',
      component: <Helpers ask={ask} />,
    });
  }
  const activeTab = tabs[tab];
  return (
    <>
      <Card
        onClick={() => basic && history.push(`asks/${ask.id}`)}
        className={`ask-card h-auto ${extraClassNames}`}
      >
        <CardHeader>
          <CardTitle>
            <>
              <div className="small d-flex mb-2">
                {ask.publishStatus === 'draft' && (
                  <Badge color="warning" className="need-badge">
                    Draft
                  </Badge>
                )}
                <Badge
                  color="primary"
                  title={ask.projectNeed.description}
                  className="need-badge"
                >
                  {ask.projectNeed.title}
                </Badge>
                <AskStatus ask={ask} />
              </div>
              {ask.title}
            </>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {basic && (
            <>
              <RichEditor
                value={ask.description}
                toolbar={basicEditorToolbar}
                readOnly
                className="lite-editor"
              />
            </>
          )}

          {!basic && (
            <>
              <Nav pills vertical="false" className="border-0 mb-3">
                {map(tabs, (t, i) => (
                  <NavItem key={i}>
                    <NavLink
                      className={classnames('nav-btn mr-2 small', {
                        active: isEqual(t.label, activeTab?.label),
                      })}
                      style={{ fontSize: 14 }}
                      onClick={() => setActiveTab(i)}
                    >
                      <FontAwesomeIcon
                        icon={t.icon}
                        className="mr-2"
                        style={{ width: 20 }}
                      />
                      {t.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <hr />
              <div className="ask-more-details">
                <TabContent activeTab={activeTab?.label}>
                  {map(tabs, (t, i) => (
                    <TabPane tabId={t.label} key={i}>
                      {t.component}
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            </>
          )}
        </CardBody>
        <CardFooter>
          <AskInfo what="statusInfo" ask={ask} />
        </CardFooter>
      </Card>
      {!basic &&
        (ask?.project?.userPermissions.includes('manage_asks') ||
          ask.amIHelping) && <Comments commentable={ask} />}
    </>
  );
};

AskDetail.propTypes = {
  ask: PropTypes.object.isRequired,
  basic: PropTypes.bool,
  extraClassNames: PropTypes.string,
};

export default observer(AskDetail);
