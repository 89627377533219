import {
  action,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { isEmpty, map } from 'lodash';

import BaseStore from './base_store';
import Announcement from '../entities/announcement';
import Filterable from '../concerns/filterable';

class AnnouncementStore extends BaseStore {
  @observable newAnnouncement = null;

  constructor(store) {
    super(store, Announcement);

    extendObservable(this, Filterable);

    this.initializeFilterable();

    makeObservable(this);
  }

  @action
  initializeNewAnnouncement() {
    this.newAnnouncement = new Announcement(
      {
        title: '',
        description: '',
        projectId:
          this.rootStore.projectStore.hasActiveProject &&
          this.rootStore.projectStore.activeProject.amIOwner
            ? this.rootStore.projectStore.activeProject.id
            : null,
      },
      this
    );
  }

  @flow
  *fetch(pms) {
    this.update({ records: [] });

    const response = yield this.client.get('/api/v1/announcements.json', {
      params: {
        ...pms,
        project_id: this.rootStore.projectStore.activeProject.id,
      },
    });

    map(response.data.announcements, announcement =>
      this.addRecord(announcement)
    );
  }

  @flow
  *fetchAnnouncementFromEncodedId(id) {
    try {
      const response = yield this.client.post(
        `/api/v1/announcements/token.json`,
        {
          token: id,
        }
      );

      return this.addRecord(response.data.announcement);
    } catch (e) {
      console.log(e);
    }
  }

  @flow
  *fetchAnnouncement(id) {
    const existingAnnouncement = this.getById(id);

    if (!isEmpty(existingAnnouncement)) return existingAnnouncement;

    try {
      const response = yield this.client.get(
        `/api/v1/announcements/${id}.json`
      );

      return this.addRecord(response.data.announcement);
    } catch (e) {
      console.log(e);
    }
  }
}

export default AnnouncementStore;
