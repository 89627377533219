import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isNull } from 'lodash';
import useStore from '../../../data/store';
import LayoutBody from '../_layout_body';

const AuthenticatedLayout = ({ children, loading }) => {
  const { appStore } = useStore();

  return (
    <div className="authenticated-layout">
      <LayoutBody forcePublic={false} loading={loading}>
        {children}
      </LayoutBody>
      {!isNull(appStore.creatable) && (
        <Modal
          backdrop="static"
          size={appStore.creatable.size || 'lg'}
          isOpen
          toggle={() => appStore.toggleCreatable()}
        >
          <ModalHeader toggle={() => appStore.toggleCreatable()}>
            {appStore.creatable.modalTitle}
          </ModalHeader>
          <ModalBody>
            {!isNull(appStore.creatable.component) && (
              <appStore.creatable.component
                toggle={() => appStore.toggleCreatable()}
                record={appStore.creatable?.record}
              />
            )}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

AuthenticatedLayout.defaultProps = {
  loading: false,
};

AuthenticatedLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default observer(AuthenticatedLayout);
