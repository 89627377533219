import React from 'react';

import { Redirect, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { isEmpty } from 'lodash';
import useStore from '../data/store';
import AuthenticatedLayout from '../views/layouts/authenticate';
import ProjectRequestLayout from '../views/layouts/project_request';

export const KEYCLOAK_AUTH_URL = '/users/auth/keycloakopenid';

export const redirectToAuth = () => {
  localStorage.setItem(
    'redirectURL',
    `${window.location.pathname}${window.location.search}`
  );

  window.location.href = KEYCLOAK_AUTH_URL;
};

const AuthenticatedRoute = ({ component, path }) => {
  const { userStore } = useStore();

  if (!userStore.userSignedIn) return redirectToAuth();
  if (!userStore.currentUser.isOnboarded && path !== '/onboard')
    return <Redirect to="/onboard" />;

  return (
    <AuthenticatedLayout>
      <ProjectRequestLayout>
        <Route exact path={path} component={component} />
      </ProjectRequestLayout>
    </AuthenticatedLayout>
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.object,
  path: PropTypes.string,
};

export default observer(AuthenticatedRoute);
