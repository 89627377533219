import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import { observer } from 'mobx-react';
import t from '../../t';
import Player from '../modules/player';
import WrappedHTML from './_wrapped_html';
import useStore from '../../data/store';

const VideoHelp = () => {
  const { appStore } = useStore();

  return (
    <Modal
      backdrop="static"
      size="lg"
      isOpen
      toggle={() => (appStore.showVideoHelp = false)}
    >
      <ModalHeader toggle={() => (appStore.showVideoHelp = false)}>
        {t(`help.${appStore.videoHelpKey}.label`)}
      </ModalHeader>
      <ModalBody className="video-help">
        <div className="video-help-holder p-2">
          <Player
            url={t(`help.${appStore.videoHelpKey}.video`)}
            controls="full"
          />
        </div>
        <div className="mt-2 pt-3 video-help-text">
          <WrappedHTML content={t(`help.${appStore.videoHelpKey}.text`)} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(VideoHelp);
