import { isEqual, isFunction, map } from 'lodash';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Tabs as ScrollableTabs, Tab } from 'react-tabs-scrollable';

function Tabs({ tabs, activeStep, onTabChange }) {
  const history = useHistory();

  // When you want to change the tab
  const changeTab = (e, index) => {
    if (isFunction(onTabChange)) onTabChange(index);
    const newUrl = `${window.location.pathname}?tab=${index}`;
    history.push(newUrl);
  };

  return (
    <ScrollableTabs activeTab={activeStep} onTabClick={changeTab}>
      {map(
        tabs,
        (t, i) =>
          t.enabled && (
            <Tab key={i}>
              {/* <NavLink */}
              {/*  className={classnames( */}
              {/*    `nav-btn mr-2 small project-settings-tab-${i}`, */}
              {/*    { */}
              {/*      active: isEqual(activeStep, i), */}
              {/*    } */}
              {/*  )} */}
              {/*  style={{ fontSize: 14 }} */}
              {/*  onClick={() => { */}
              {/*    changeTab(i); */}
              {/*  }} */}
              {/* > */}
              {/*  */}
              {/* </NavLink> */}
              <FontAwesomeIcon
                icon={t.icon}
                className="mr-2"
                style={{ width: 20 }}
              />
              {t.label}
            </Tab>
          )
      )}
    </ScrollableTabs>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  onTabChange: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default observer(Tabs);
