import { extendObservable, flow, makeObservable, observable } from 'mobx';

import { map } from 'lodash';
import BaseStore from './base_store';
import ProjectHelper from '../entities/project_helper';
import Filterable from '../concerns/filterable';

class HelperStore extends BaseStore {
  @observable helpable;

  constructor(store, helpable) {
    super(store, ProjectHelper);

    this.helpable = helpable;

    extendObservable(this, Filterable);

    this.initializeFilterable({
      search: '',
      status: '',
      role: '',
      needs: [],
    });

    makeObservable(this);
  }

  @flow
  *fetch(pms) {
    const response = yield this.client.get(
      `/api/v1/helpables/${this.helpable.id}/helpers.json`,
      {
        params: pms,
      }
    );

    if (response.data.helpers) {
      this.update({
        records: map(response.data.helpers, helper => this.addRecord(helper)),
        fetched: true,
      });
    }
  }
}

export default HelperStore;
