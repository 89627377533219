import { computed, makeObservable } from 'mobx';

import { isEqual } from 'lodash';

import BaseEntity from './base_entity';

class WalletEntry extends BaseEntity {
  /* eslint-disable */
  fromWalletId;
  toWalletId;
  from;
  to;
  description;
  amount;
  status;
  createdAt;
  walletId
  updatedAt;
  context;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
  }

  @computed
  get isDebit() {
    return isEqual(this.fromWalletId, this.walletId);
  }

  @computed
  get isCredit() {
    return isEqual(this.toWalletId, this.walletId);
  }

  @computed
  get textClass() {
    if (this.isVoided || this.isPending) return 'text-muted';
    if (this.isCredit) return 'text-success';
    if (this.isDebit) return 'text-danger';

    return '';
  }

  @computed
  get isVoided() {
    return isEqual(this.status, 'voided');
  }

  @computed
  get isPending() {
    return isEqual(this.status, 'pending');
  }

  @computed
  get statusClass() {
    if (this.isPending) return 'warning';
    if (this.isVoided) return 'danger';
    return 'success';
  }
}

export default WalletEntry;
