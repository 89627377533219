import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const SharedInfoHeader = ({ record, showDescription }) => (
  <div className="ask-header">
    <div className="w-100  my-2">
      {showDescription && <p>{record.description}</p>}
    </div>
  </div>
);

SharedInfoHeader.defaultProps = {
  showDescription: false,
};

SharedInfoHeader.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired,
  showDescription: PropTypes.bool,
};

export default observer(SharedInfoHeader);
