import { action, computed, makeObservable, observable } from 'mobx';
import { isNull } from 'lodash';

import BaseEntity from './base_entity';

class Pagination extends BaseEntity {
  /* eslint-disable */
  @observable count = 0;
  @observable current = 0;
  @observable pages = null;
  @observable previous = null;
  @observable next = null;
  @observable params = {};
  @observable filterType = 'fetch';
  @observable perPage = 10;
  initialParams = {};
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
    this.initialParams = value.params;
  }

  @computed
  get hasNextPage() {
    return !isNull(this.next);
  }

  @action
  fetch() {
    this.store.fetch({
      ...this.params,
      page: this.current,
      per_page: this.perPage,
    });
  }

  @action
  updateParams(params) {
    this.update({
      params: {
        ...this.params,
        ...params,
      },
    });
  }

  @action
  gotoPage(page) {
    this.update({ current: page });
    this.fetch();
  }

  @action
  resetPageAndFetch() {
    this.store.update({ records: [] });
    this.update({ next: null, previous: null });

    this.gotoPage(1);
  }

  @action
  reset() {
    this.update({ current: 1, params: this.initialParams });
    this.fetch();
  }

  @action
  gotoNext() {
    this.gotoPage(this.next);
  }
}

export default Pagination;
