import React from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import Player from '../modules/player';

const HelpableBasicDetails = ({ helpable, preview = false }) => {
  const createdDate = moment(helpable.createdAt).format('MMMM Do YYYY');
  const resolvedVideoUrl = () => {
    if (helpable.videoUrl.includes('?')) {
      return helpable.videoUrl.substring(0, helpable.videoUrl.indexOf('?'));
    }
    return helpable.videoUrl;
  };
  return (
    <Card className="mb-4">
      <CardBody>
        <div className="project-details">
          <div className="mb-3">
            <h1>{helpable.title} </h1>
            {!isEmpty(helpable.tagline) && (
              <h2 className="text-muted">{helpable.tagline}</h2>
            )}
            <div className="small text-muted created">
              {helpable.createdBy.name}
              {' | '}
              {createdDate}
            </div>
          </div>
          {helpable.hasVideoAttached && (
            <div className="public-cta-holder">
              <Player url={helpable.videoUrl} muted />
              {preview && (
                <div className="xxx-small text-muted">
                  Video:{resolvedVideoUrl()}
                </div>
              )}
            </div>
          )}
          <div
            className="mt-3 project-description"
            dangerouslySetInnerHTML={{
              __html: helpable.description,
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

HelpableBasicDetails.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
  preview: PropTypes.bool,
};

export default observer(HelpableBasicDetails);
