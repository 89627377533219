import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import PublicLayout from '../views/layouts/public_layout';

const PublicRoute = ({ component, path }) => {
  const Component = component;
  return (
    <PublicLayout>
      <Route exact path={path} component={Component} />
    </PublicLayout>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.object,
  path: PropTypes.string,
};

export default observer(PublicRoute);
