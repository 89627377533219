import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import withProject from '../layouts/with_project';
import ShareDetail from './detail';

function Share({ match }) {
  const { projectStore } = useStore();
  const project = projectStore.activeProject;

  useEffect(() => {
    if (isEmpty(project)) return;

    project.fetchShares();
  }, [project?.id]);

  const share = find(project.shares, { id: match.params?.share_id });

  if (isEmpty(share)) return null;

  return <ShareDetail share={share} />;
}

Share.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};
export default withProject(observer(Share));
