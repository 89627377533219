import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import FilteredList from '../modules/filtered_list';
import { projectFilters } from '../../helpers/filter_helpers';
import HubProjectStore from '../../data/stores/project_store';
import ProjectsList from './_projects_list';

const HubProjects = ({ hub }) => {
  useEffect(() => {
    if (isEmpty(hub.projects)) {
      hub.update({
        projects: new HubProjectStore(hub.store.rootStore, hub),
      });
    }
  }, []);

  if (isEmpty(hub.projects)) return null;

  return (
    <Card className="h-auto tour-helpers">
      <CardBody>
        <FilteredList
          listComponent={ProjectsList}
          store={hub.projects}
          filters={projectFilters}
          changeTrigger={hub.id}
          recordClassName="col-xl-4"
          listClassName="tour-helper-entries"
        />
      </CardBody>
    </Card>
  );
};

HubProjects.propTypes = {
  hub: PropTypes.instanceOf(Object),
};

export default observer(HubProjects);
