import { computed, flow, makeObservable, observable } from 'mobx';
import { map } from 'lodash';

import BaseStore from './base_store';
import Comment from '../entities/comment';

class CommentStore extends BaseStore {
  @observable share;

  @observable commentable = {};

  constructor(store, commentable) {
    super(store, Comment);

    this.commentable = commentable;

    makeObservable(this);
  }

  @computed
  get sortedComments() {
    const com = [...this.records];
    return com.sort(function(a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }

  @flow
  *fetch() {
    try {
      const response = yield this.client.get(`/api/v1/comments`, {
        params: {
          ...this.commentable,
        },
      });

      this.update({
        records: [
          ...this.records,
          ...map(response.data.comments, entry => this.addRecord(entry)),
        ],
        fetched: true,
      });
    } catch (e) {
      this.update({ fetched: true });
    }
  }
}

export default CommentStore;
