import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import AskInfo from './_ask_info';
import useStore from '../../data/store';
import AskDetail from './_ask_detail';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import withProject from '../layouts/with_project';

const AskDetailPage = ({ match }) => {
  const { askStore } = useStore();
  const [ask, setAsk] = useState();

  useEffect(() => {
    askStore.fetchAsk(match.params?.askId).then(a => {
      if (a?.fetched) {
        setAsk(a);
      }
    });
  }, []);

  if (isEmpty(ask)) return null;

  return (
    <ThreeColumnLayout
      center={<AskDetail extraClassNames="tour-ask-detail-card" ask={ask} />}
      right={<AskInfo what="statusSummary" ask={ask} />}
    />
  );
};

AskDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withProject(observer(AskDetailPage));
