import { Card, CardBody, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useStore from '../../data/store';
import WalletTransactions from '../wallets';
import t from '../../t';
import Tabs from '../shared/tabs';
import Insights from '../insights';

function UserAccount() {
  const { userStore } = useStore();
  const user = userStore.currentUser;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    parseInt(searchParams.get('tab')) || 0
  );

  const allTabs = [
    {
      label: t(`settings.wallet`),
      component: <WalletTransactions disableHeader walletable={user} />,
      enabled: true,
    },
    // {
    //   label: t(`common.insights`),
    //   component: <Insights />,
    //   enabled: true,
    // },
  ];

  const [activeComponent, setActiveComponent] = useState(allTabs[0].component);

  return (
    <div className="helpable-settings ">
      <div className="manage-project">
        <Card className="p-2">
          <CardBody className="d-flex">
            <div className="tabs-container">
              <div className="mb-3">
                <h5 className="text-center text-uppercase text-muted">
                  User Account
                </h5>
              </div>
              <Tabs
                tabs={allTabs}
                activeStep={activeTab}
                onTabChange={index => {
                  setActiveTab(index);
                  setActiveComponent(allTabs[index].component);
                }}
              />
            </div>
            <hr />
            <div className="main-tabs-container">{activeComponent}</div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default observer(UserAccount);
