import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { StoreProvider } from '../data/provider';
import RootStore from '../data';
import AppRoutes from '../routes';

const rootStore = new RootStore();
const EntryPoint = () => (
  <StoreProvider value={rootStore}>
    <BrowserRouter>
      <div className="main-app">
        <ToastContainer />
        <AppRoutes />
      </div>
    </BrowserRouter>
  </StoreProvider>
);

export default EntryPoint;
