import {
  action,
  computed,
  extendObservable,
  makeObservable,
  observable,
} from 'mobx';
import {
  camelCase,
  filter,
  find,
  includes,
  isEmpty,
  map,
  uniqBy,
} from 'lodash';
import notification from '../concerns/notfications';
import client from '../axiosClient';

class BaseStore {
  /* eslint-disable */
  rootStore;
  client;
  Entity;
  @observable records = [];
  @observable fetching = false;
  @observable fetched = false;
  /* eslint-enable */

  constructor(store, entity) {
    makeObservable(this);

    this.rootStore = store;
    this.client = client;
    this.Entity = entity;

    extendObservable(this, notification);
  }

  getById = id => this.getByParams({ id });

  getByParams = params => find(this.records, params);

  getMultipleByParams = params => filter(this.records, params);

  getMultipleById = ids => filter(this.records, r => includes(ids, r.id));



  @computed
  get filteredRecords() {
    return this.records;
  }

  @computed
  get hasRecords() {
    return !isEmpty(this.records);
  }

  @action
  initialize(params) {
    this.update(params);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  addRecord(record) {
    const obj = new this.Entity(record, this);

    this.records = uniqBy(
      [...this.records, new this.Entity(record, this)],
      'id'
    );

    return obj;
  }
}

export default BaseStore;
