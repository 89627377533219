import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { find, includes, isEmpty, isEqual, isObject } from 'lodash';

import { serialize } from 'object-to-formdata';
import fileExtension from 'file-extension';
import BaseEntity from './base_entity';
import { videoFormats } from '../../views/modules/player';
import Commentable from '../concerns/commentable';
import Videoable from '../concerns/videoable';
import t from '../../t';

class Announcement extends BaseEntity {
  /* eslint-disable */
  @observable subject;
  @observable body;
  @observable announceableId = '';
  @observable announcementType = 'in_app';
  @observable announceableType = 'Helpable';
  @observable usePersonalSmtp = false;
  @observable status;
  @observable projectId;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);
    extendObservable(this, Commentable);
    extendObservable(this, Videoable);
    this.handleConstruction(value);
  }

  @computed
  get statusOptions() {
    return [
      { label: 'Draft', value: 'draft' },
      { label: 'Published', value: 'published' },
    ];
  }

  @computed
  get isGeneral() {
    return (
      this.announceableType === 'Helpable' && this.announcementType === 'in_app'
    );
  }

  @computed
  get announcementTypeTitle() {
    if (this.isGeneral) return t('announcements.types.general');
    if (this.announceableType === 'Category') return this.projectNeed?.title;

    return t('announcements.types.project_status');
  }

  @computed
  get announcementTypeBadgeColor() {
    if (this.isGeneral) return 'info';
    if (this.announceableType === 'ProjectNeed') return 'primary';

    return 'success';
  }

  @computed
  get projectNeed() {
    return find(this.project.projectNeeds, {
      id: this.announceableId,
    });
  }

  createFormData(helpers) {
    const formData = serialize(this.basicParams);

    if (this.fileValid) {
      formData.append('video', this.file);
    }

    if (!isEmpty(helpers)) {
      formData.append('project_helper_ids', helpers);
    }

    return formData;
  }

  @computed
  get project() {
    return this.store.rootStore.projectStore.activeProject;
  }

  @computed
  get basicParams() {
    return {
      subject: this.subject,
      body: this.body,
      announcement_type: this.announcementType,
      announceable_type:
        this.announcementType === 'status' ? 'Helpable' : this.announceableType,
      announceable_id: this.announceableId,
      video_url: this.videoUrl,
      use_personal_smtp: this.usePersonalSmtp,
      status: this.status,
      thumbnail: this.thumbnail,
    };
  }

  @action
  handleConstruction(value) {
    const val = { ...value };

    this.initialize(val);
  }

  @flow
  *save(helperIds) {
    const data = this.createFormData(helperIds);

    try {
      const response = this.persisted
        ? yield this.client.put(`/api/v1/announcements/${this.id}.json`, data)
        : yield this.client.post('/api/v1/announcements.json', data);

      this.update({ file: undefined });
      this.notifySuccess('Announcement saved successfully.');

      if (this.announcementType !== 'direct') {
        this.handleConstruction(response.data.announcement);

        // push into the store records
        const existingRecord = this.store.getById(this.id);

        if (!isEmpty(existingRecord)) {
          existingRecord.update(this.json);
        } else {
          this.store.addRecord(response.data.announcement);
        }

        if (isEqual(this.store.entity?.id, this.id)) {
          this.store.entity.update(this.json);
        }
      }

      return response.data.announcement;
    } catch (e) {
      this.store.notifyError('Announcement failed due to technical reasons.');

      return null;
    }
  }
}

export default Announcement;
