import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';

import { map, orderBy } from 'lodash';
import BaseStore from './base_store';
import Ask from '../entities/ask';
import Filterable from '../concerns/filterable';

class AskStore extends BaseStore {
  @observable newAsk = null;

  constructor(store) {
    super(store, Ask);

    extendObservable(this, Filterable);

    this.initializeFilterable({
      name: '',
      ownership: '',
      status: '',
      reward_type: '',
      expires_at: { from: null, to: null },
      reward_amount: { min: 0, max: 500 },
    });

    makeObservable(this);
  }

  @override
  get filteredRecords() {
    return orderBy(this.records, ['createdAt'], ['desc']);
  }

  @action
  findOrCreate(id) {
    return this.getById(id) || new this.Entity({ id, fetched: false }, this);
  }

  @action
  initializeNewAsk() {
    this.newAsk = new Ask(
      {
        title: '',
        description: '',
        projectId: this.rootStore.projectStore.activeProjectId,
        maxHelpers: 1,
      },
      this
    );
  }

  @flow
  *fetch(pms) {
    const response = yield this.client.get('/api/v1/asks.json', {
      params: {
        project_id: this.rootStore.projectStore.activeProjectId,
        ...pms,
      },
    });

    if (response.data.asks) {
      this.update({
        records: map(response.data.asks, ask => this.addRecord(ask)),
        fetched: true,
      });
    }
  }

  @flow
  *fetchAsk(id) {
    const response = yield this.client.get(`/api/v1/asks/${id}.json`, {});
    if (response.data.success) {
      const a = this.addRecord(response.data.ask);
      a.update({ fetched: true });
      return a;
    }
  }
}

export default AskStore;
