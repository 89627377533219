import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import JoinWrapper from './wrapper';

const HubJoin = ({ match }) => (
  <JoinWrapper
    match={{
      params: {
        id: window.location.host.split('.')[0],
      },
    }}
    helpableType="account"
  />
);

HubJoin.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default observer(HubJoin);
