import React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ProjectFormWrapper = ({ helpable, form, title }) => (
  <div className="project_form_wrapper">
    <div className="project_form_wrapper__sticky">
      <div className="border_box project_form_wrapper__content">
        {title && <h2>{title}</h2>}
        {helpable.persisted && (
          <div className="text-right project-info-btn">
            <Button
              onClick={() => helpable.save()}
              color="primary"
              disabled={helpable.saving}
            >
              {helpable.saving ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin /> Updating...
                </>
              ) : (
                'Update Project'
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
    {form}
  </div>
);

ProjectFormWrapper.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
};
export default observer(ProjectFormWrapper);
