import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ProjectBasicDetails from './_project_basic_details';

const HelpableInfo = ({ helpable, preview = false }) => (
  <div className="project-card-group">
    <ProjectBasicDetails helpable={helpable} preview={preview} />
  </div>
);

HelpableInfo.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
  preview: PropTypes.bool,
};

export default observer(HelpableInfo);
