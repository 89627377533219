import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ProjectCard from '../../helpables/project_card';

const ThreeColumnLayout = ({ isProject = true, left, center, right }) => (
  <div>
    <div className="bl-content">
      <Row className="mb-3 project-dashboard">
        <Col lg={6} xl={3}>
          {isProject && <ProjectCard />}
          {left}
        </Col>
        <Col className="main-section" xl={6}>
          {center}
        </Col>
        <Col lg={6} className="" xl={3}>
          {right}
        </Col>
      </Row>
    </div>
  </div>
);

ThreeColumnLayout.propTypes = {
  isProject: PropTypes.bool,
  right: PropTypes.node,
  center: PropTypes.node,
  left: PropTypes.node,
};

export default observer(ThreeColumnLayout);
