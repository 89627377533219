import { action, computed, flow, makeObservable, observable } from 'mobx';
import { isEqual, map } from 'lodash';
import BaseEntity from './base_entity';

class ProjectHelper extends BaseEntity {
  /* eslint-disable */
  userId;
  projectId;
  myHelps = [];
  @observable role = 'helper';
  @observable status;
  @observable setupComplete;
  @observable share;
  @observable subscribedNeedIds;
  @observable subscribedNeedTitles;
  @observable qualifyingStatement;
  @observable permissions;
  @observable isBuilder;
  @observable friendlyName;
  @observable activities = [];
  @observable walletId;
  @observable offeringsAndSubscriptions = {}
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.handleConstruction(value);
  }

  @computed
  get roleClass() {
    if (this.isBuilder) return 'primary';

    return 'secondary';
  }

  @computed
  get permissionClass() {
    if (this.isBuilder || this.canManage) return 'primary';

    return 'secondary';
  }

  @computed
  get permissionText() {
    if (this.isBuilder) return 'everything';

    return this.permissions;
  }

  @computed
  get canBeRemoved() {
    return this.isConfirmed && this.project?.isBuilder;
  }

  @computed
  get canChangeNeeds() {
    return (
      this.project.amIHelping &&
      (isEqual(this.userId, this.currentUser.id) || this.project.isBuilder)
    );
  }

  @computed
  get canChangePermission() {
    if (isEqual(this.userId, this.currentUser.id)) return false;

    return this.project.amIHelping && this.project.isBuilder;
  }

  @computed
  get statusClass() {
    if (this.isRequested) return 'warning';
    if (this.isConfirmed) return 'success';
    if (this.isDeclined) return 'danger';
    return 'info';
  }

  @computed
  get statusIcon() {
    // return 'circle'
    // keeping it simple
    if (this.isRequested) return 'circle';
    if (this.isConfirmed) return 'check-double';
    if (this.isDeclined) return 'ban';
    return 'info-circle';
  }

  @computed
  get project() {
    return (
      this.store.helpable ||
      this.store.rootStore.projectStore.getById(this.projectId)
    );
  }

  @computed
  get isPending() {
    return isEqual(this.status, 'pending');
  }

  @computed
  get isRequested() {
    return isEqual(this.status, 'requested');
  }

  @computed
  get isConfirmed() {
    return isEqual(this.status, 'confirmed');
  }

  @computed
  get isDeclined() {
    return isEqual(this.status, 'declined');
  }

  @computed
  get user() {
    return this.store.rootStore.userStore.getById(this.userId);
  }

  @computed
  get canManage() {
    return this.project.canManage || this.isBuilder;
  }

  @action
  handleConstruction(value) {
    const val = { ...value };

    if (val.user) {
      this.store.rootStore.userStore.addRecord(value.user);
      val.userId = val.user.id;

      delete val.user;
    }

    this.initialize(val);
  }

  @flow
  *updateStatus(status, isDelete = false) {
    const response = yield this.client.post(
      `/api/v1/helpables/${this.projectId}/update_helper_status.json`,
      { status, project_helper_id: this.id }
    );

    if (response.data.success) {
      this.update({ status });

      if (isDelete) {
        this.destroy();
      }
    }
  }

  @flow
  *updateNeeds(needs) {
    const response = yield this.client.put(
      `/api/v1/helpables/${this.projectId}/update_helper_needs.json`,
      { needs: map(needs, n => n.value), project_helper_id: this.id }
    );

    if (response.data.success) {
      this.handleConstruction(response.data.record);
    }
  }

  @flow
  *updatePermissions(permissions) {
    const response = yield this.client.post(
      `/api/v1/helpables/${this.projectId}/helper_permissions.json`,
      {
        permissions,
        project_helper_id: this.id,
        authorised_helper_id: this.project.myProjectHelper.id,
      }
    );

    if (response.data.success) {
      this.update({ permissions });
    }
  }

  @flow
  *fetchActivities() {
    const response = yield this.client.get(
      `/api/v1/helpables/${this.store.rootStore.projectStore.activeProjectId}/helper_activities.json`
    );

    if (response.data.activities) {
      // eslint-disable-next-line no-unused-expressions
      this.update({
        activities: response.data.activities,
      });
    }
  }
}

export default ProjectHelper;
