import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

const NavIconLink = ({
  icon,
  link,
  title,
  className,
  onClick,
  disabled,
  id,
}) => {
  // check if the current url has the link in it, if so, add the active class
  const currentUrl = window.location.pathname;
  const active = currentUrl === link ? 'header-nav-active' : '';

  return (
    <Link
      to={link}
      className={`${active} ${className}`}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      <div className="text-center">
        <div className="link-icon">
          <FontAwesomeIcon className="icon" icon={icon} />
        </div>
        {!isEmpty(title) && <div className="title">{title}</div>}
      </div>
    </Link>
  );
};

NavIconLink.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default observer(NavIconLink);
