import { isEmpty, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Badge, Button, Table } from 'reactstrap';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory } from 'react-router-dom';
import useStore from '../../data/store';
import RejectProject from './_reject_project';

const ProjectsList = ({ records, store }) => {
  const { projectStore, userStore, impersonateUserStore } = useStore();

  const { account } = projectStore;
  const history = useHistory();

  return (
    <div className="list-view">
      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Created By</th>
              <th>Status</th>
              <th>Approved At</th>
              {account.approvalRequired && <th />}
            </tr>
          </thead>
          <tbody>
            {map(records, (record, i) => (
              <tr key={i}>
                <td>
                  <a href={record.joinLink}> {record.title}</a>
                </td>
                <td>
                  {userStore.currentUser.isSystem && (
                    <Link
                      to=""
                      onClick={() =>
                        impersonateUserStore.startImpersonate(
                          record.createdBy?.id, // eslint-disable-line
                          history,
                          record.dashboardLink
                        )
                      }
                    >
                      {record.createdBy?.name}
                    </Link>
                  )}
                  {!userStore.currentUser.isSystem && (
                    <span>
                      {record.createdBy?.name || <Badge>Unavailable</Badge>}
                    </span>
                  )}
                </td>
                <td>
                  <Badge color={record.statusClass}>{record.status}</Badge>
                </td>
                <td>
                  {record.approvedAt ? (
                    moment(record.approvedAt).format('MMM DD, YYYY')
                  ) : (
                    <Badge>Unapproved</Badge>
                  )}
                </td>
                {account.approvalRequired && (
                  <td>
                    {!isEmpty(record.approvalRequestedAt) ? (
                      <div className="d-flex" style={{ gap: 10 }}>
                        {(record.canBeApproved || record.isRejected) && (
                          <Button
                            onClick={() =>
                              confirmAlert({
                                title: 'Approval Confirmation',
                                message:
                                  'Are you sure you want to approve this project?',
                                buttons: [
                                  {
                                    label: 'Approve',
                                    onClick: () => record.approveProject(),
                                  },
                                  {
                                    label: 'Cancel',
                                    onClick: null,
                                  },
                                ],
                              })
                            }
                            color="primary"
                          >
                            Approve
                          </Button>
                        )}
                        {record.canBeApproved && (
                          <RejectProject project={record} />
                        )}
                      </div>
                    ) : (
                      <div>
                        {isEmpty(record.approvedAt) &&
                          'Approval Not Requested Yet'}
                      </div>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

ProjectsList.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
};

export default observer(ProjectsList);
