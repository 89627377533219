import React, { useEffect } from 'react';

import { includes, isEmpty } from 'lodash';
import { Alert, Badge, Card, CardBody, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import PublicView from '../helpables/_public_view';
import About from '../helpables/_about';
import HelpersIndex from '../helpers';
import WalletTransactions from '../wallets';
import Share from '../helpables/_shares';
import OtherSettingsForm from '../helpables/_other_settings';
import ProjectFormWrapper from '../shared/_project_form_wrapper';
import Tabs from '../shared/tabs';
import t from '../../t';
import Insights from '../insights';
import Users from '../platform_admin/users';
import useStore from '../../data/store';
import HubProjects from '../hub_projects';

export function HelpableBadge({ helpable }) {
  const { status } = helpable;
  return (
    <Badge
      color={classnames({
        warning: includes(['pending'], status),
        success: status === 'active',
        danger: includes(['inactive', 'rejected', 'archived'], status),
      })}
      className="small project-status-badge btn-block"
    >
      {t(
        helpable.isPending && !isEmpty(helpable.approvalRequestedAt)
          ? 'common.status.pending_approval'
          : `common.status.${status}`
      )}
    </Badge>
  );
}

HelpableBadge.propTypes = { helpable: PropTypes.any };

function Settings({ helpable }) {
  const { userStore, appStore } = useStore();
  const { currentUser } = userStore;
  // useEffect to set the project step from the URL if there is one
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      helpable.update({ step: parseInt(tab) });
    }
  }, []);

  const allTabs = () => {
    const stabs = [];

    if (helpable.canManage) {
      stabs.push({
        label: t(`settings.info`),
        component: <About helpable={helpable} />,
        enabled: true,
      });

      stabs.push({
        label: t(`settings.other_settings`),
        enabled: helpable.isActive,
        component: (
          <ProjectFormWrapper
            form={<OtherSettingsForm helpable={helpable} />}
            helpable={helpable}
            title={t(`settings.other_settings`)}
          />
        ),
      });
      stabs.push({
        label: t(`settings.wallet`),
        component: <WalletTransactions walletable={helpable} disableHeader />,
        enabled: true,
      });

      stabs.push({
        label: t(`settings.share_links`),
        component: <Share shareable={helpable} />,
        enabled: helpable.isActive,
      });

      stabs.push({
        label: t(`settings.helpers`),
        component: <HelpersIndex helpable={helpable} />,
        enabled: helpable.isActive,
      });

      stabs.push({
        label: t(`settings.preview`),
        enabled: true,
        component: <PublicView helpable={helpable} preview />,
      });

      if (helpable.canManage && !helpable.isProject) {
        stabs.push({
          label: t(`settings.projects`),
          enabled: true,
          component: <HubProjects hub={helpable} />,
        });
      }

      // stabs.push({
      //   label: t(`common.insights`),
      //   component: <Insights helpable={helpable} />,
      //   enabled: helpable.isActive,
      // });
    }

    return stabs;
  };

  const tabs = allTabs();
  const activeTab = tabs[helpable.step];

  if (isEmpty(helpable)) return null;

  if (helpable.canManage || !helpable.persisted) {
    return (
      <div className="manage-project">
        <Card className="p-2">
          <CardBody>
            <Form>
              <div className="tabs-container">
                <div className="mb-3">
                  <h5 className="text-center text-uppercase text-muted">
                    {helpable.isProject ? 'Project Admin' : 'Hub Admin'}
                  </h5>
                  <div>
                    <HelpableBadge helpable={helpable} />
                  </div>
                </div>
                <Tabs
                  tabs={tabs}
                  onTabChange={index => {
                    helpable.update({ step: index });
                  }}
                  activeStep={helpable.step}
                />
              </div>
              <hr />
              <div className="main-tabs-container">
                <div className="project-form">{activeTab.component}</div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
  return (
    <>
      <Alert color="warning">
        You are not the owner or maintainer of this project, so we are showing
        you the public view of the helpable. Contact{' '}
        <strong>{helpable.createdBy.name}</strong> to get access to this
        helpable.
      </Alert>
      <PublicView project={helpable} />
    </>
  );
}

Settings.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Settings);
