import * as React from 'react';
import { observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';
import { Card, CardBody, Form } from 'reactstrap';
import { useState } from 'react';
import useStore from '../../data/store';
import Tabs from '../shared/tabs';
import t from '../../t';
import Users from './users';
import Insights from '../insights';
import Hubs from './hubs';

function PlatformAdmin() {
  const [tab, setTab] = useState(0);
  const { userStore } = useStore();
  const { currentUser } = userStore;

  if (!currentUser.isSystem) return <Redirect to="/" />;

  const tabs = [
    {
      label: t(`platform.admin.hubs`),
      component: <Hubs />,
      enabled: true,
    },
    {
      label: t(`platform.admin.users`),
      component: <Users />,
      enabled: true,
    },
    {
      label: t(`platform.admin.insights`),
      component: <Insights />,
      enabled: true,
    },
  ];

  const activeTab = tabs[tab];

  return (
    <div className="platform-admin-settings">
      <div className="manage-project">
        <Card className="p-2">
          <CardBody className="d-flex">
            <div className="tabs-container">
              <div className="mb-3">
                <h5 className="text-center text-uppercase text-muted">
                  Platform Admin
                </h5>
              </div>
              <Tabs
                tabs={tabs}
                onTabChange={index => {
                  setTab(index);
                }}
                activeStep={tab}
              />
            </div>
            <hr />
            <div className="main-tabs-container">
              <div className="project-form">{activeTab.component}</div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default observer(PlatformAdmin);
