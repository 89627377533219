import { action } from 'mobx';
import { isNull } from 'lodash';

const Walletable = {
  get balance() {
    return this.wallet?.balance;
  },

  updateWallet: action(function(type, balance) {
    if (isNull(this.wallet)) return;
    this.wallet?.update({ balance });
  }),
};

export default Walletable;
