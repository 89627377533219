import { Link } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import LOGO from '../../assets/images/icon.svg';
import useStore from '../../data/store';

const logo = () => {
  const { appStore } = useStore();
  return (
    <div className="logo">
      <Link to="/">
        <img src={LOGO} alt="logo" width={40} height={40} />
        <span className="brand">
          <span className="font-weight-bold">HELP</span>BUILD
        </span>
      </Link>
      {appStore.isBeta && (
        <sup className="font-weight-bold xxx-small p-1 text-black bg-warning ml-1">
          beta
        </sup>
      )}
      {!appStore.isProduction && (
        <sup className="xxx-small p-1 bg-danger ml-1">dev</sup>
      )}
    </div>
  );
};

export default observer(logo);
