import { action } from 'mobx';
import CommentStore from '../stores/comment_store';

const Commentable = {
  commentStore: null,

  fetchComments: action(function() {
    this.commentStore = new CommentStore(this.store.rootStore, {
      commentable_type: this.constructor.name,
      commentable_id: this.id,
    });
    return this.commentStore.fetch();
  }),
};

export default Commentable;
