import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Filters from './_filters';

const FilteredList = ({
  store,
  listComponent,
  filters,
  changeTrigger,
  listClassName,
  autoFetch,
}) => {
  const ListComponent = listComponent;

  useEffect(() => {
    store.pagination.fetch();
  }, [changeTrigger]);

  return (
    <div className="filtered-lists">
      <Filters
        filters={filters}
        pagination={store.pagination}
        autoFetch={autoFetch}
      />
      {isEmpty(store.filteredRecords) && (
        <>
          <hr />
          <strong className="text-muted">Nothing here.</strong>
        </>
      )}
      {!isEmpty(store.filteredRecords) && (
        <div className="records mt-4">
          <div className={listClassName}>
            <div>
              <div className="medium mb-1 text-muted">
                {store.filteredRecords.length} records found
              </div>
              <ListComponent records={store.filteredRecords} store={store} />
            </div>
          </div>
        </div>
      )}
      {store.hasRecords && store.pagination.hasNextPage && (
        <div className="text-center">
          <Button
            color="link"
            onClick={() => store.pagination.gotoNext()}
            size="sm"
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

FilteredList.defaultProps = {
  listClassName: '',
  autoFetch: true,
};

FilteredList.propTypes = {
  store: PropTypes.instanceOf(Object).isRequired,
  listComponent: PropTypes.object.isRequired,
  filters: PropTypes.instanceOf(Array).isRequired,
  changeTrigger: PropTypes.string.isRequired,
  listClassName: PropTypes.string,
  autoFetch: PropTypes.bool,
};

export default observer(FilteredList);
