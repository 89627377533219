import { Button, Input } from 'reactstrap';
import { filter, includes, isEmpty, map, startCase, toLower } from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import useStore from '../../data/store';
import Need from './_need';

function SystemNeedsPicker({ helpable, selected, toggle }) {
  const { appStore } = useStore();
  const [search, setSearch] = useState('');

  const selectableNeeds = helpable?.isProject
    ? helpable.projectNeeds
    : appStore.systemProjectNeeds;

  const filteredNeeds = filter(
    selectableNeeds,
    need =>
      toLower(need.title).includes(toLower(search)) ||
      toLower(need.description).includes(toLower(search)) ||
      toLower(need.metadata).includes(toLower(search))
  );

  return (
    <>
      <p className="text-center">
        {helpable?.isProject && (
          <span>
            This is what they need help with. <br />
            Select any that you can help on:
          </span>
        )}
        {!helpable?.isProject && (
          <span className="text-sm-center">
            Pick at least one area that you can help with:
          </span>
        )}
      </p>
      <div className="project-needs mb-4">
        <Input
          placeholder="Type here to search"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div className="needs-container" style={{ gap: '10px' }}>
          {isEmpty(filteredNeeds) ? (
            <p>Nothing found</p>
          ) : (
            map(filteredNeeds, (systemNeed, i) => {
              const isChecked = includes(selected, systemNeed.id);

              return (
                <Need
                  key={i}
                  need={systemNeed}
                  checked={isChecked}
                  toggle={toggle}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

SystemNeedsPicker.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  toggle: PropTypes.func.isRequired,
};

export default observer(SystemNeedsPicker);
