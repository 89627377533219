import { map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import HelperDetail from './_helper_detail';

const HelpersFeed = ({ records }) => (
  <div className="feed-view">
    <Row>
      {map(records, (record, i) => (
        <Col lg={4}>
          <HelperDetail helper={record} key={i} basic />
        </Col>
      ))}
    </Row>
  </div>
);

HelpersFeed.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
};

export default observer(HelpersFeed);
