import { flow } from 'mobx';
import Share from '../entities/share';

const Shareable = {
  shares: [],
  fetchedShares: false,
  fetchingShares: false,
  fetchShares: flow(function*() {
    if (this.fetchingShares || this.fetchedShares) return;

    try {
      this.update({ fetchingShares: true });

      const response = yield this.client.get(`/api/v1/shares`, {
        params: {
          shareable_id: this.id,
          shareable_type: this.helpableType,
        },
      });

      this.shares = response.data.shares.map(
        share => new Share(share, this.store)
      );

      this.update({ fetchingShares: false, fetchedShares: true });
    } catch (e) {
      console.log(e);

      this.update({ fetchingShares: false });
    }
  }),
};

export default Shareable;
