import React, { useState } from 'react';

import { Button, Form as ReactForm } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import t from '../../t';

import useStore from '../../data/store';
import BasicProjectForm from '../shared/_basic_project_form';
import Helpable from '../../data/entities/helpable';

const BasicForm = ({ toggle }) => {
  const [isCreating, setIsCreating] = useState(false);

  const { projectStore } = useStore();

  const [project] = useState(
    new Helpable({ title: '', description: '' }, projectStore)
  );

  const handleChange = e => {
    project.update({ [e.target.name]: e.target.value });
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    setIsCreating(true);

    project.create().then(res => {
      if (res) {
        toggle();
        window.location.href = `/p/${project.id}/`;
      }
    });
  };

  return (
    <ReactForm className="project-form" onSubmit={handleFormSubmit}>
      <BasicProjectForm
        helpable={project}
        handleChange={handleChange}
        full={false}
      />
      <Button
        className="btn-primary w-100"
        type="submit"
        disabled={isEmpty(project.title.trim()) || isCreating}
      >
        {!isCreating && t('btn_create_project')}
        {isCreating && (
          <>
            <FontAwesomeIcon icon={faSpinner} spin /> Working...
          </>
        )}
      </Button>
    </ReactForm>
  );
};

BasicForm.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default observer(BasicForm);
