import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import useStore from '../../data/store';
import GiveBonus from '../asks/_give_bonus';

function BuyTokens({ wallet, color, className }) {
  const { userStore } = useStore();
  return (
    <>
      <Button
        tag="a"
        href={`/checkout?w=${wallet.id}`}
        size="sm"
        className={className}
        color={color}
      >
        Buy Tokens
      </Button>
      {userStore.currentUser && userStore.currentUser.isSystem && (
        <GiveBonus
          fromWalletId={userStore.currentUser.systemWalletId}
          maxLimit={10000}
          toWalletId={wallet.id}
          defaultMessage="Gift by Ecosystem"
          btnTitle="Gift Tokens"
        />
      )}
    </>
  );
}

BuyTokens.defaultProps = {
  color: 'primary',
  className: '',
};

BuyTokens.propTypes = {
  wallet: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default observer(BuyTokens);
