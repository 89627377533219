import { extendObservable, flow, makeObservable } from 'mobx';
import { map } from 'lodash';

import BaseStore from './base_store';
import Filterable from '../concerns/filterable';
import User from '../entities/user';

class ImpersonateUserStore extends BaseStore {
  constructor(store) {
    super(store, User);

    extendObservable(this, Filterable);

    this.initializeFilterable({
      query: '',
    });

    makeObservable(this);
  }

  @flow
  *fetch(pms) {
    try {
      const response = yield this.client.get(`/api/v1/users.json`, {
        params: pms,
      });

      this.update({
        records: [
          ...this.records,
          ...map(response.data.users, entry => this.addRecord(entry)),
        ],
        fetched: true,
      });

      this.pagination.update(response.data.pagination);
    } catch (e) {
      this.update({ fetched: true });
    }
  }

  @flow
  *startImpersonate(id, history, forwardTo) {
    try {
      const response = yield this.client.post(
        `/api/v1/users/${id}/impersonate.json`
      );

      this.rootStore.userStore.update({
        currentUser: new User(response.data.user, this),
      });

      this.rootStore.projectStore.fetch(() => {
        if (!this.rootStore.projectStore.hasActiveProject) {
          this.rootStore.projectStore.setDefaultActiveProject();
        }
        //   check  if forwardTo is present, then redirect to that page
        if (forwardTo && history) {
          history.push(forwardTo);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  @flow
  *stopImpersonate() {
    try {
      const response = yield this.client.post(
        `/api/v1/users/stop_impersonating`
      );

      this.rootStore.userStore.update({
        currentUser: new User(response.data.user, this),
      });

      this.rootStore.projectStore.fetch(() => {
        if (!this.rootStore.projectStore.hasActiveProject) {
          this.rootStore.projectStore.setDefaultActiveProject();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export default ImpersonateUserStore;
