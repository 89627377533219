import React from 'react';
import PropTypes from 'prop-types';
import { join, map } from 'lodash';
import ReactPlayer from 'react-player';
import LOGO from '../../../assets/images/helpbuild-logo-white-text.png';

export const videoFormats = ['png', 'mp4', 'mkv', 'avi', 'webm', 'mov'];
export const videoExtensions = join(
  map(videoFormats, ext => `.${ext}`),
  ','
);

const Player = ({ url, onStart, onPause }) => (
  <div className="player-wrapper">
    <ReactPlayer
      controls
      onPause={onPause}
      onStart={onStart}
      className="react-player"
      url={url}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload', // For some browsers
          },
        },
      }}
    />
    <div className="helpbuild-logo-watermark">
      <img src={LOGO} alt="watermark" />
    </div>
  </div>
);

Player.propTypes = {
  url: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onPause: PropTypes.func,
};

export default Player;
