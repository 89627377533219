import { extendObservable, makeObservable, observable } from 'mobx';
import BaseEntity from './base_entity';
import Shareable from '../concerns/shareable';

class Account extends BaseEntity {
  @observable name = '';

  @observable subdomain = '';

  @observable description = '';

  @observable isAdmin = false;

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    extendObservable(this, Shareable);

    this.initialize(value);
  }
}

export default Account;
