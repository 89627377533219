import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Alert,
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import useStore from '../../data/store';
import Player from '../modules/player';
import t from '../../t';

const Projects = ({ account }) => {
  const { projectStore } = useStore();
  const [projects, setProjects] = useState([]);
  const history = useHistory();

  useEffect(() => {
    projectStore.fetchCurrentAccountProjects().then(res => {
      if (res.success) {
        setProjects(res.projects);
      }
    });
  }, []);

  return (
    <>
      <Row className="container mt-3">
        <Col sm={12} className="text-muted">
          {(account.isPubliclyVisible || account.hasAccess) && (
            <div className="text-center">
              <Row className="mt-3 justify-content-start">
                {map(projects, project => (
                  <Card className="p-0 pb-3 mr-3" key={project.id}>
                    <CardBody>
                      <Player
                        url={project.videoUrl}
                        controls="playOnly"
                        muted={false}
                      />
                    </CardBody>
                    <CardHeader>
                      <CardTitle>
                        <a href={project.joinLink}>
                          <strong>{project.title}</strong>
                        </a>
                      </CardTitle>
                    </CardHeader>
                  </Card>
                ))}
                {projects.length === 0 && (
                  <Col sm={12} className="text-center">
                    {t('account.projects.empty')}
                  </Col>
                )}
              </Row>
            </div>
          )}
          {!account.isPubliclyVisible && !account.hasAccess && (
            <Alert color="danger" className="text-center">
              {t('account.projects.private')}
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
Projects.propTypes = {
  account: PropTypes.instanceOf(Object).isRequired,
};
export default observer(Projects);
