import { isEqual, map, reject } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Table } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import Checkbox from '../shared/_checkbox';

const UserList = ({ records, store }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { share } = store;

  const allChecked = selectedUsers.length === records.length;
  const disableInvitation = selectedUsers.length <= 0;

  const resendInvitations = () => {
    const users = records.filter(
      r => r.joinStatus === 'pending' && selectedUsers.includes(r.id)
    );

    if (users.length <= 0) return;

    share.resendInvitations(users.map(u => u.id));

    setSelectedUsers([]);
  };

  const handleResend = () => {
    confirmAlert({
      title: 'Resend Confirmation',
      message: 'Resend project join invitation to the selected users?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Send',
          onClick: resendInvitations,
        },
      ],
    });
  };

  return (
    <div>
      {!disableInvitation && (
        <div className="mb-2 text-right">
          <Button
            onClick={handleResend}
            disabled={disableInvitation}
            size="sm"
            color={disableInvitation ? 'secondary' : 'primary'}
          >
            Resend Invitation
          </Button>
        </div>
      )}

      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th style={{ width: 40 }}>
                <Checkbox
                  onChange={() => {
                    if (allChecked) return setSelectedUsers([]);

                    setSelectedUsers(records.map(r => r.id));
                  }}
                  checked={allChecked}
                />
              </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Join Status</th>
            </tr>
          </thead>
          <tbody>
            {map(records, (record, i) => (
              <tr key={i}>
                <th style={{ width: 40 }}>
                  <Checkbox
                    disabled={isEqual(record.joinStatus, 'joined')}
                    onChange={() => {
                      if (selectedUsers.includes(record.id)) {
                        return setSelectedUsers(
                          reject(selectedUsers, u => isEqual(u, record.id))
                        );
                      }

                      setSelectedUsers([...selectedUsers, record.id]);
                    }}
                    checked={
                      isEqual(record.joinStatus, 'joined')
                        ? false
                        : selectedUsers.includes(record.id)
                    }
                  />
                </th>
                <td>{record.firstName}</td>
                <td>{record.lastName}</td>
                <td>{record.email}</td>
                <td>{record.joinStatus}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

UserList.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
};

export default observer(UserList);
