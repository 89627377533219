import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment/moment';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Player from '../modules/player';
import FilteredList from '../modules/filtered_list';
import { projectHelperFilters } from '../../helpers/filter_helpers';
import UsersList from './_user_list';

function ShareDetail({ share }) {
  return (
    <div>
      <div>
        <div className="bl-content project-dashboard">
          <Row className="mb-3">
            <Col className="tour-project-stats" lg={3}>
              <Card className="h-auto mb-2">
                <CardBody>
                  <div className="">
                    <h4>{share.name}</h4>
                    <div
                      className="small d-flex flex-column"
                      style={{
                        fontSize: 14,
                        lineHeight: 'inherit',
                      }}
                    >
                      <strong>Optional Message:</strong> {share.optionalMessage}
                    </div>
                  </div>
                </CardBody>
              </Card>
              {share.videoUrl && (
                <Card className="h-auto mb-2">
                  <CardBody>
                    <div className="project-details">
                      <h4>Video</h4>
                      <Player url={share.videoUrl} onEnded={() => {}} />
                    </div>
                  </CardBody>
                </Card>
              )}
              <Card className="h-auto mb-2">
                <CardBody>
                  <div className="project-details">
                    <h4>Share Info</h4>
                    <div className="pd-info">
                      <p>
                        <span>Max Users</span>
                        {share.maxUsers === -1 ? 'unlimited' : share.maxUsers}
                      </p>
                      <p>
                        <span>Visit Count</span>
                        {share.visitCount}
                      </p>
                      <p>
                        <span>Users Count</span>
                        {share.userCount}
                      </p>
                      <p>
                        <span>Created At</span>
                        {moment(share.createdAt).format('MMM DD, YYYY')}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9}>
              <Card>
                <CardBody>
                  <FilteredList
                    autoFetch={false}
                    store={share.users}
                    filters={projectHelperFilters}
                    listComponent={UsersList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

ShareDetail.propTypes = {
  share: PropTypes.instanceOf(Object).isRequired,
};

export default observer(ShareDetail);
