import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { camelCase, isEmpty, isEqual, map } from 'lodash';

import client from '../axiosClient';
import notification from '../concerns/notfications';

class BaseEntity {
  /* eslint-disable */
  store;
  client;
  @observable dirty = false;
  @observable json = {};
  @observable setForDelete = false;
  @observable id = '';
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    this.store = store;
    this.client = client;
    this.json = value;

    extendObservable(this, notification);
  }

  @computed
  get currentUser() {
    return this.store.rootStore.userStore.currentUser;
  }

  @computed
  get persisted() {
    return !isEmpty(this.id);
  }

  @action
  initialize(params) {
    this.update(params);
  }

  @action
  update(params, updateServer) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    if (updateServer) this.updateServer(params);
  }

  @action
  destroy() {
    if (isEmpty(this.store)) return;

    this.store.records.splice(this.store.records.indexOf(this), 1);
  }

  @flow
  *updateServer(params) {
    yield this.client.put(this.updateUrl, params);
  }
}

export default BaseEntity;
