import React from 'react';

import { observer } from 'mobx-react';
import { Card, CardBody } from 'reactstrap';

import useStore from '../../data/store';
import FilteredList from '../modules/filtered_list';
import { usersFilters } from '../../helpers/filter_helpers';
import UsersList from './_users_list';

const Users = () => {
  const { impersonateUserStore } = useStore();

  return (
    <>
      <Card>
        <CardBody>
          <FilteredList
            store={impersonateUserStore}
            filters={usersFilters()}
            listComponent={UsersList}
            recordClassName="col-xl-4"
            autoFetch={false}
            changeTrigger=""
          />
        </CardBody>
      </Card>
    </>
  );
};

export default observer(Users);
