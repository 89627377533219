import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import RichEditor from '../modules/rich_text_editor';
import { basicEditorToolbar } from '../../helpers/shared_helpers';

const Info = ({ ask }) => (
  <>
    <div className="ask-description">
      <h5>Description</h5>
      <RichEditor
        value={ask.description}
        toolbar={basicEditorToolbar}
        readOnly
        className="lite-editor"
      />
    </div>
    <div className="acceptance-criteria mt-3">
      <h5>Acceptance Criteria</h5>
      <RichEditor
        value={ask.acceptanceConditions}
        toolbar={basicEditorToolbar}
        readOnly
        className="lite-editor"
      />
    </div>
  </>
);

Info.propTypes = {
  ask: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Info);
