import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import InsightChart from '../shared/_insight_chart';

const Insights = ({ helpable }) => (
  <div>
    <div
      style={{
        border: '1px solid #f3f3f3',
        padding: 30,
        borderRadius: 15,
        marginBottom: 30,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 30,
        alignItems: 'center',
      }}
    >
      <div
        style={{
          fontSize: 33,
          textAlign: 'center',
          width: 60,
          height: 60,
          background: '#f3f3f3',
          borderRadius: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        55
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        Growth
        <div style={{ fontSize: 33, textAlign: 'center' }}>
          <FontAwesomeIcon icon={faChartLine} className="text-success" /> 55%
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        Engagement
        <div style={{ fontSize: 33, textAlign: 'center' }}>
          <FontAwesomeIcon icon={faChartLine} className="text-danger" /> -15%
        </div>
      </div>
    </div>
    {helpable && (
      <>
        <InsightChart
          insight={helpable.growthInsights}
          title="Growth"
          description="Growth Expected Vs Actual"
        />
        <InsightChart
          insight={helpable.engagementInsights}
          title="Engagements"
          description="Representation of Engagements across various types i.e Asks, Needs etc"
        />
      </>
    )}
  </div>
);

Insights.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Insights);
