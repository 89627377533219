import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import PublicLayout from '../public_layout';
import NothingDoing from '../../../assets/images/nothing-doing.svg';
import t from '../../../t';

const Tumbleweed = ({ messageKey }) => (
  <div className="text-center">
    <div className="text-center">
      <img src={NothingDoing} alt="logo" className="img-fluid" width="25%" />
    </div>
    <div className="ml-5 mt-4">
      <h2>{t(`tumbleweed.${messageKey}.main`)}</h2>
      <p className="font-weight-bold">
        {t(`tumbleweed.${messageKey}.subheading`)}
      </p>
    </div>
  </div>
);
Tumbleweed.defaultProps = {
  messageKey: 'default',
};

Tumbleweed.propTypes = {
  messageKey: PropTypes.string.isRequired,
};

export default observer(Tumbleweed);
