import React from 'react';
import { observer } from 'mobx-react';
import Tumbleweed from './authenticate/tumbleweed';
import useStore from '../../data/store';
import withProject from './with_project';

const WaitingRoom = () => {
  const { userStore } = useStore();

  if (userStore.currentUser?.status === 'pending') {
    return <Tumbleweed messageKey="pending" />;
  }
  return <Tumbleweed />;
};

export default withProject(observer(WaitingRoom));
