import { flow, makeObservable } from 'mobx';
import { map } from 'lodash';

import BaseStore from './base_store';
import Helpable from '../entities/helpable';

class HubStore extends BaseStore {
  constructor(store) {
    super(store, Helpable);

    // extendObservable(this, Filterable);
    //
    // this.initializeFilterable();

    makeObservable(this);
  }

  @flow
  *fetch() {
    this.update({ records: [] });

    const response = yield this.client.get('/api/v1/helpables/hubs.json');

    map(response.data.hubs, hub => this.addRecord(hub));
  }
}

export default HubStore;
