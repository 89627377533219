import { extendObservable, flow, makeObservable, observable } from 'mobx';

import { map } from 'lodash';
import BaseStore from './base_store';
import Filterable from '../concerns/filterable';
import Helpable from '../entities/helpable';

class HubProjectStore extends BaseStore {
  @observable helpable;

  constructor(store, hub) {
    super(store, Helpable);

    this.hub = hub;

    extendObservable(this, Filterable);

    this.initializeFilterable({
      search: '',
      status: '',
    });

    makeObservable(this);
  }

  @flow
  *fetch(pms) {
    const response = yield this.client.get(
      `/api/v1/helpables/hub_projects.json`,
      {
        params: pms,
      }
    );

    if (response.data.projects) {
      this.update({
        records: map(response.data.projects, project =>
          this.addRecord(project)
        ),
        fetched: true,
      });
    }
  }
}

export default HubProjectStore;
