import { Button, Input } from 'reactstrap';
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { videoExtensions } from '../modules/player';

const VideoUploader = ({ attachable, onAttachment }) => {
  const handleFileUpload = e => {
    if (isEmpty(e.target.files)) return;

    const file = e.target.files[0];

    attachable.update({ file });
    onAttachment(file);
  };

  return (
    <>
      <Button
        type="button"
        color="secondary"
        className="cursor-pointer file-btn"
      >
        <Input
          type="file"
          onChange={handleFileUpload}
          accept={videoExtensions}
        />
        <FontAwesomeIcon icon={['fas', 'upload']} />
      </Button>
    </>
  );
};

VideoUploader.defaultProps = {
  onAttachment: () => {},
};

VideoUploader.propTypes = {
  attachable: PropTypes.object,
  onAttachment: PropTypes.func,
};

export default observer(VideoUploader);
