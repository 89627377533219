import { filter, find, includes, isEmpty, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactTimeAgo from 'react-time-ago';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import { toSentence } from '../../helpers/shared_helpers';
import t from '../../t';
import AskDetail from '../asks/_ask_detail';
import HelperDetail from './_helper_detail';
import FilteredList from '../modules/filtered_list';
import HelpersFeed from './_helpers_feed';
import { projectHelperFilters } from '../../helpers/filter_helpers';

const HelpersLeaderboard = ({ records }) => {
  const badges = ['🥇', '🥈', '🥉'];
  const { projectStore } = useStore();
  const getBadge = idx => {
    if (idx >= 4) return <span className="position">{idx}</span>;
    return <span className="font-weight-bold">{badges[idx - 1]}</span>;
  };

  return (
    <Card className="h-auto leaderboard">
      <CardHeader className="d-flex justify-content-between ">
        <FontAwesomeIcon className="mr-2" icon="star" />
        {t('leaderboard.title')}
        <FontAwesomeIcon className="mr-2" icon="star" />
      </CardHeader>
      <CardBody>
        {isEmpty(projectStore.activeProject.leaderboard) && (
          <div className="text-center">{t(`leaderboard.empty`)}</div>
        )}
        <div className="table-responsive">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              {map(projectStore.activeProject.leaderboard, (record, index) => (
                <tr key={record.id} className="helper">
                  <td>{getBadge(index + 1)}</td>
                  <td className={`${index <= 2 ? 'top-helper' : ''}`}>
                    {record.name}
                  </td>
                  <td>{record.helpfulness_score}</td>
                  <td>
                    <FontAwesomeIcon
                      icon="info-circle"
                      id={`leader-${record.id}`}
                      className="text-muted"
                    />
                    <UncontrolledTooltip target={`leader-${record.id}`}>
                      <div className="text-left small">
                        {Object.keys(record.helpfulness_score_data).map(key => (
                          <div key={key}>
                            {t(`leaderboard.${key}`)}:{' '}
                            {record.helpfulness_score_data[key]}
                          </div>
                        ))}
                      </div>
                    </UncontrolledTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};
HelpersLeaderboard.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
};

export default observer(HelpersLeaderboard);
