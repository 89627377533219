import React from 'react';

import { observer } from 'mobx-react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import FilteredList from '../modules/filtered_list';
import { projectHelperFilters } from '../../helpers/filter_helpers';
import HelpersCondensedFeed from './_helpers_condensed_feed';

const HelpersIndex = ({ helpable }) => (
  <Card className="h-auto tour-helpers">
    <CardBody>
      <FilteredList
        listComponent={HelpersCondensedFeed}
        store={helpable.helpers}
        filters={projectHelperFilters}
        changeTrigger={helpable.id}
        recordClassName="col-xl-4"
        listClassName="tour-helper-entries"
      />
    </CardBody>
  </Card>
);

HelpersIndex.propTypes = {
  helpable: PropTypes.instanceOf(Object),
};

export default observer(HelpersIndex);
