import React from 'react';

import { isEqual } from 'lodash';
import { Input } from 'reactstrap';
import {
  DateRange,
  InputRangeTag,
  SearchInput,
  SelectTag,
  ReactSelectTag,
} from '../views/shared/form_components';

export const renderFilter = (filter, handleChange, withLabel = false) => {
  switch (filter.type) {
    case 'hidden':
      return <Input type="hidden" value={filter.value} />;
    case 'search':
      return <SearchInput handleChange={handleChange} input={filter} />;

    case 'select':
      return <ReactSelectTag handleChange={handleChange} input={filter} />;

    case 'date-range':
      return <DateRange handleChange={handleChange} input={filter} />;

    case 'input-range':
      return <InputRangeTag handleChange={handleChange} input={filter} />;

    case 'react-select':
      return <ReactSelectTag handleChange={handleChange} input={filter} />;

    default:
      return null;
  }
};

export const announcementFilters = projectStore => [
  // {
  //   name: 'projects_ids',
  //   type: 'react-select',
  //   placeholder: 'Select Projects',
  //   label: 'Projects',
  //   options: projectStore.recordsAsSelectOptionsWithoutGlobal,
  //   show: !projectStore.hasActiveProject,
  //   isMulti: true,
  // },
  // {
  //   name: 'name',
  //   type: 'search',
  //   placeholder: 'Search...',
  //   label: 'Name / Description',
  // },
  // {
  //   name: 'announcement_type',
  //   type: 'select',
  //   placeholder: 'Search...',
  //   label: 'Announcement Type',
  //   options: [
  //     { label: 'All', value: '' },
  //     { label: 'General Update', value: 'general_update' },
  //     { label: 'Project Need', value: 'project_need' },
  //   ],
  // },
  // {
  //   name: 'created_at',
  //   type: 'date-range',
  //   placeholder: 'Select Date',
  //   label: 'Created Date',
  // },
];

export const askFilters = askStore => [
  {
    label: 'Type',
    name: 'ownership',
    type: 'select',
    placeholder: 'Select...',
    options: [
      { label: 'All Asks', value: '' },
      { label: "Asks I'm working on", value: 'mine' },
      { label: 'Asks I added', value: 'owner' },
    ],
  },
  {
    label: 'Status',
    name: 'status',
    type: 'select',
    placeholder: 'Select...',
    options: [
      { label: 'All', value: '' },
      { label: 'Completed', value: 'completed' },
      { label: 'Needs Review', value: 'review' },
      { label: 'In Progress', value: 'started' },
      { label: 'Not Started', value: 'pending' },
      { label: 'Expired', value: 'expired' },
    ],
  },
  {
    name: 'name',
    type: 'search',
    placeholder: 'Search...',
  },
];

export const projectHelperFilters = [
  {
    name: 'query',
    type: 'search',
    placeholder: 'Search...',
    label: 'Name/Email',
    size: 6,
  },
  {
    name: 'status',
    size: 6,
    label: 'Status',
    type: 'select',
    placeholder: 'Select...',
    options: [
      { label: 'All', value: '' },
      { label: 'Requested', value: 'requested' },
      { label: 'Active', value: 'active' },
    ],
  },
];

export const projectFilters = [
  {
    name: 'search',
    type: 'search',
    placeholder: 'Search...',
    label: 'Title',
    size: 6,
  },
  {
    name: 'status',
    size: 6,
    label: 'Status',
    type: 'select',
    placeholder: 'Select...',
    options: [
      { label: 'All', value: '' },
      { label: 'Approval Requested', value: 'approval_requested' },
      { label: 'Active', value: 'active' },
      { label: 'Draft', value: 'pending' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Archived', value: 'archived' },
    ],
  },
];

export const usersFilters = () => [
  {
    name: 'query',
    type: 'search',
    label: 'Name/Email',
    size: 4,
  },
];

export const shareUsersFilters = () => [
  {
    name: 'query',
    type: 'search',
    label: 'Name/Email',
    size: 4,
  },
  {
    name: 'query',
    type: 'search',
    label: 'Name/Email',
    size: 4,
  },
];

export const walletFilters = () => [
  {
    name: 'amount',
    type: 'input-range',
    label: 'Amount',
    size: 4,
  },
  {
    name: 'created_at',
    type: 'date-range',
    placeholder: 'Select Date',
    label: 'Transaction Date',
    size: 4,
  },
  {
    name: 'context',
    type: 'select',
    placeholder: 'Select...',
    label: 'Transaction Type',
    size: 4,
    options: [
      { label: 'All', value: '' },
      { label: 'Transferred', value: 'transferred' },
      { label: 'Earned', value: 'earned' },
      { label: 'Minted', value: 'minted' },
      { label: 'Purchased', value: 'purchased' },
      { label: 'Reserved', value: 'reserved' },
    ],
  },
];
