import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { filter, isEmpty, map } from 'lodash';
import { Badge, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import HelperActions from './_helper_actions';
import { renderFilter } from '../../helpers/filter_helpers';
import SubscribedNeeds from '../helpables/_subscribed_needs';

const HelperDetailCondensed = ({ helper, basic = false }) => {
  const { userStore, appStore } = useStore();

  if (isEmpty(helper)) return null;

  const permissionsAsOptions = helper.store.helpable.isProject
    ? [
        { label: 'Manage Project', value: 'manage_project' },
        { label: 'Manage Announcements', value: 'manage_announcements' },
        { label: 'Manage Needs', value: 'manage_needs' },
        { label: 'Manage Helpers', value: 'manage_helpers' },
        { label: 'Manage Asks', value: 'manage_asks' },
      ]
    : [{ label: 'Manage Account', value: 'manage_account' }];

  const permissions = {
    name: 'permissions',
    type: 'react-select',
    placeholder: 'Permission...',
    value: helper.permissions,
    isMulti: true,
    classnames: 'x-small',
    disabled: !helper.store.helpable.canManage,
    options: permissionsAsOptions,
  };

  const handleChange = e => {
    helper.updatePermissions(e.target.value);
  };

  const isAccount = !helper.store.helpable?.isProject || false;

  const subscribedNeeds = filter(appStore.systemProjectNeeds, need =>
    helper.subscribedNeedIds?.includes(need.id)
  );

  return (
    <>
      <Row>
        <Col sm={3}>
          <div>
            <span className={`text-${helper.statusClass} mr-2 small`}>
              {helper.userId === userStore.currentUser.id ? (
                <Badge className="badge-info">YOU</Badge>
              ) : (
                <FontAwesomeIcon
                  icon={helper.statusIcon}
                  title={helper.status}
                />
              )}
            </span>
            <span className="mr-2 font-weight-bold">{helper.friendlyName}</span>
          </div>
        </Col>
        <Col sm={6}>
          <p>{renderFilter(permissions, handleChange)}</p>
          <div className="small">
            {map(helper.subscribedNeedTitles, (n, i) => (
              <Badge key={i} className="mr-1" color="primary">
                {n}
              </Badge>
            ))}
          </div>
        </Col>
        <Col>
          <HelperActions record={helper} />
        </Col>
      </Row>
      <Row>
        <Col>
          <blockquote className="small font-weight-bold">
            {helper.qualifyingStatement}
          </blockquote>
          {isAccount && <SubscribedNeeds needs={subscribedNeeds} title="" />}
        </Col>
      </Row>
    </>
  );
};

HelperDetailCondensed.propTypes = {
  helper: PropTypes.object.isRequired,
  basic: PropTypes.bool,
  helpable: PropTypes.instanceOf(Object).is,
};

export default observer(HelperDetailCondensed);
