import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import Announcement from './_announcement';
import withProject from '../layouts/with_project';

const AnnouncementDetailPage = ({ match }) => {
  const { announcementStore } = useStore();
  const [announcement, setAnnouncement] = useState();

  useEffect(() => {
    announcementStore
      .fetchAnnouncement(match.params?.announcementId)
      .then(a => {
        setAnnouncement(a);
      });
  }, []);

  if (isEmpty(announcement)) return null;

  return (
    <ThreeColumnLayout
      center={<Announcement announcement={announcement} />}
      right={<HelpersLeaderboard />}
    />
  );
};

AnnouncementDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withProject(observer(AnnouncementDetailPage));
