import { action, computed } from 'mobx';
import { serialize } from 'object-to-formdata';
import fileExtension from 'file-extension';
import { includes, isEmpty, isObject } from 'lodash';
import { videoExtensions, videoFormats } from '../../views/modules/player';

const Videoable = {
  videoUrl: '',
  file: null,
  thumbnail: '',
  thumbnails: [],
  formDataParams: action(function() {
    const formData = serialize(this.basicParams);

    if (this.fileValid) {
      formData.append('video', this.file);
    }
    return formData;
  }),
  get whiteListedVideoExtensions() {
    return videoFormats;
  },
  get showThumbnailButton() {
    return this.file;
  },
  get showThumbnailPicker() {
    return this.file || !isEmpty(this.videoUrl);
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },
  get videoUrlToShow() {
    if (this.file) {
      return URL.createObjectURL(this.file);
    }
    if (this.videoUrl) return this.videoUrl;

    return 'https://cdn.wizewerx.tech/helpbuild/replace-video-1080p-240710.mp4';
  },
  get fileValid() {
    const extension = fileExtension(this.file?.name);

    return (
      isObject(this.file) &&
      includes(this.whiteListedVideoExtensions, extension)
    );
  },
  get hasVideoAttached() {
    return !isEmpty(this.videoUrl);
  },
  removeAttachment: action(function() {
    this.file = null;
  }),
  createThumbnails() {
    const video = document.createElement('video');
    video.src = this.videoUrlToShow;
    const intervalTime = 1; // Interval in seconds
    const maxThumbnails = 10; // Maximum number of thumbnails
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    video.addEventListener('loadedmetadata', () => {
      const { duration } = video;
      const frameInterval = intervalTime * 1000; // Interval in milliseconds
      const totalFrames = Math.min(
        Math.ceil(duration / intervalTime),
        maxThumbnails
      );

      const captureThumbnail = currentTime => {
        video.currentTime = currentTime;
        video.pause();
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailData = canvas.toDataURL('image/jpeg');
        this.thumbnails.push(thumbnailData);
      };

      let currentFrame = 0;
      const captureInterval = setInterval(() => {
        if (currentFrame >= totalFrames) {
          clearInterval(captureInterval);
          video.pause();
          return;
        }
        const currentTime = currentFrame * intervalTime;
        try {
          captureThumbnail(currentTime);
          currentFrame++;
        } catch (e) {
          console.log(e);
          clearInterval(captureInterval);
        }
      }, frameInterval);
    });
  },
};

export default Videoable;
