import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { isBoolean, map } from 'lodash';
import { Form, Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { observer } from 'mobx-react';

import { renderFilter } from '../../../helpers/filter_helpers';

const Filters = ({ pagination, filters, autoFetch }) => {
  const handleChange = e => {
    pagination.update({
      params: { ...pagination.params, [e.target.name]: e.target.value },
    });

    if (autoFetch) pagination.resetPageAndFetch();
  };

  const submitForm = e => {
    pagination.resetPageAndFetch();
    e.preventDefault();
  };

  return (
    <>
      <Row className="tour-filters">
        <Form className="filter-form" onSubmit={e => submitForm(e)}>
          {map(filters, (filter, i) => (
            <Fragment key={i}>
              {isBoolean(filter.show) && !filter.show ? null : (
                <Col key={i} md={filter.size || 'auto'}>
                  <FormGroup>
                    <Label>{filter.label}</Label>
                    {renderFilter(
                      { ...filter, value: pagination.params[filter.name] },
                      handleChange,
                      true
                    )}
                  </FormGroup>
                </Col>
              )}
            </Fragment>
          ))}
        </Form>
      </Row>
      {!autoFetch && (
        <div className="d-flex justify-content-end mt-4">
          <Button type="submit" color="primary">
            Filter
          </Button>
        </div>
      )}
    </>
  );
};

Filters.defaultProps = {
  autoFetch: true,
};

Filters.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.instanceOf(Array).isRequired,
  autoFetch: PropTypes.bool,
};

export default observer(Filters);
