import React from 'react';
import PropTypes from 'prop-types';
import Announcement from './_announcement';
import useStore from '../../data/store';

function AnnouncementListing({ records }) {
  const { announcementStore } = useStore();

  return (
    <div className="feed-view">
      {records.map(record => (
        <Announcement announcement={record} hyperlink basic />
      ))}
    </div>
  );
}

AnnouncementListing.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
};

export default AnnouncementListing;
