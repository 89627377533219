import React, { useEffect } from 'react';

import { Switch, useLocation } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import { observer } from 'mobx-react';

import AuthenticatedRoute from './AuthenticatedRoute';
import HubJoin from '../views/join/hub';

import useStore from '../data/store';
import PublicRoute from './PublicRoute';
import Settings from '../views/settings';
import HelpersIndex from '../views/helpers';
import NotFound from '../views/public/404';
import Join from '../views/join';
import AskDetailPage from '../views/asks/_ask_detail_page';
import UserAccount from '../views/users/account';
import WaitingRoom from '../views/layouts/waiting_room';
import Share from '../views/share';
import AsksPage from '../views/asks/page';
import ActivitiesPage from '../views/activities/page';
import Announcements from '../views/announcements';
import AnnouncementDetailPage from '../views/announcements/_announcement_detail';
import Onboard from '../views/onboard';
import PublicAnnouncementDetailPage from '../views/announcements/_public_announcement_detail';
import HubAdmin from '../views/hub_admin';
import { Hub404 } from '../views/layouts/404s';
import AccountShare from '../views/account_share';
import Hub from '../views/dashboard/hub';
import CheckAccessCode from '../views/onboard/check_access_code';
import PlatformAdmin from '../views/platform_admin';
import HelpShow from '../views/help/show';

const AppRoutes = () => {
  const { userStore, projectStore, appStore } = useStore();

  useEffect(() => {
    appStore.fetchSystemNeeds();
    projectStore.fetchCurrentAccount();

    userStore.fetchCurrentUser(() => {
      projectStore.fetch(() => {
        if (!projectStore.hasActiveProject) {
          projectStore.setDefaultActiveProject();
        }
      });
    });
    const handleContextMenu = e => {
      if (e.target.tagName.toLowerCase() === 'video') {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // better, more granular routing - allows for more control over the routes and the order they are defined
  const allRoutes = [
    {
      route: '/pu/:announcement_id',
      component: PublicAnnouncementDetailPage,
      auth: false,
    },
    { route: '/hub404', component: Hub404, auth: false },
    { route: '/help/:id', component: HelpShow, auth: false },
    { route: '/check-access-code', component: CheckAccessCode, auth: false },
    { route: '/onboard', component: Onboard, auth: true },
    { route: '/platform_admin', component: PlatformAdmin, auth: true },
    { route: '/waiting-room', component: WaitingRoom, auth: true },
    {
      route: '/p/:id/announcements/:announcementId',
      component: AnnouncementDetailPage,
      auth: true,
    },
    { route: '/p/:id/asks/:askId', component: AskDetailPage, auth: true },
    { route: '/p/:id/share/:share_id', component: Share, auth: true },
    { route: '/p/:id/asks/', component: AsksPage, auth: true },
    { route: '/p/:id/waiting', component: WaitingRoom, auth: true },
    { route: '/p/:id/activities', component: ActivitiesPage, auth: true },
    { route: '/p/:id/announcements', component: Announcements, auth: true },
    { route: '/p/:id/settings', component: Settings, auth: true },
    { route: '/p/:id/helpers', component: HelpersIndex, auth: true },
    { route: '/p/:id/join', component: Join, auth: false },
    { route: '/p/:id', component: AsksPage, auth: true },
    { route: '/join', component: HubJoin, auth: false },
    { route: '/user', component: UserAccount, auth: true },
    { route: '/settings', component: HubAdmin, auth: true },
    { route: '/', component: Hub, auth: !isEmpty(userStore.currentUser) },
    { route: '*', component: NotFound, auth: false },
  ];

  const routes = () => (
    <Switch>
      {map(allRoutes, (r, i) =>
        r.auth ? (
          <AuthenticatedRoute
            key={`a-${i}`}
            path={r.route}
            component={r.component}
          />
        ) : (
          <PublicRoute key={`a-${i}`} path={r.route} component={r.component} />
        )
      )}
    </Switch>
  );

  if (userStore.fetchingCurrentUser || projectStore.fetching) {
    return null;
  }

  // Redirect to the route that was requested before login
  const redirectRoute = localStorage.getItem('redirectURL');

  if (!isEmpty(redirectRoute) && userStore.userSignedIn) {
    localStorage.removeItem('redirectURL');
    window.location.href = redirectRoute;

    return null;
  }

  return <>{routes()}</>;
};

export default observer(AppRoutes);
