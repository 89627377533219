import React, {useEffect} from 'react';

import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {Card} from 'reactstrap';

import MoreDetails from './_ask_detail';
import AskFooter from './_ask_info';
import SharedInfoHeader from '../shared/_shared_info_header';

const Ask = ({ record }) => {
  useEffect(() => {
    record.fetch();
  }, []);

  return (
    <Card className="ask-card mb-3 cursor-pointer">
      <Card onClick={() => record.update({ moreDetails: true })}>
        <SharedInfoHeader record={record} />
      </Card>
      {record.moreDetails && (
        <MoreDetails
          ask={record}
          isOpen
          toggle={() => record.update({ moreDetails: false })}
        />
      )}
      <AskFooter basic ask={record} />
    </Card>
  );
};

Ask.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Ask);
