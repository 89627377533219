import {
  Button,
  Form as ReactForm,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStore from '../../data/store';

const GiveBonus = ({
  fromWalletId,
  defaultMessage,
  toWalletId,
  type,
  btnTitle,
  maxLimit,
}) => {
  const [open, setOpen] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [note, setNote] = useState(
    defaultMessage || 'Bonus for incredible performance'
  );
  const { walletEntryStore } = useStore();

  useEffect(() => {
    setBonus(0);
    setNote(defaultMessage || 'Bonus for incredible performance');
  }, [open]);

  const handleSubmit = e => {
    e.preventDefault();
    walletEntryStore.giveBonus(fromWalletId, {
      to_wallet_id: toWalletId,
      amount: bonus,
      note,
    });

    setOpen(false);
  };

  return (
    <>
      <Button id="bonus-popover" size="xs" color={type} onClick={() => {}}>
        {btnTitle}
      </Button>
      <Popover
        flip
        toggle={() => {
          setOpen(!open);
        }}
        target="bonus-popover"
        placement="bottom"
        isOpen={open}
        trigger="legacy"
      >
        <PopoverHeader className="small">Give Bonus</PopoverHeader>
        <PopoverBody>
          <ReactForm className="bonus-form" onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Amount</Label>
              <Input
                className="small mr-2 h-25"
                name="howMuch"
                type="number"
                placeholder="Amount"
                max={maxLimit}
                required
                value={bonus}
                onChange={e => setBonus(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Note</Label>
              <Input
                className="small"
                name="howMuch"
                placeholder="Amount"
                type="textarea"
                required
                value={note}
                onChange={e => setNote(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" size="sm" type="submit">
              Send
            </Button>
          </ReactForm>
        </PopoverBody>
      </Popover>
    </>
  );
};

GiveBonus.defaultProps = {
  type: 'link',
  btnTitle: 'Give Bonus',
  maxLimit: 1000,
};

GiveBonus.propTypes = {
  fromWalletId: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  toWalletId: PropTypes.string.isRequired,
  type: PropTypes.string,
  btnTitle: PropTypes.string,
  maxLimit: PropTypes.number,
};

export default observer(GiveBonus);
