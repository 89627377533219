import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const WrappedHTML = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

WrappedHTML.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default observer(WrappedHTML);
