import React from 'react';
import { Badge } from 'reactstrap';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import t from '../../t';

const AskStatus = ({ ask }) => (
  <Badge color={ask.statusClass} className="ask-status">
    {t(`ask.status.${ask.status.toLowerCase()}.label`)}
  </Badge>
);

AskStatus.propTypes = {
  ask: PropTypes.instanceOf(Object).isRequired,
};

export default observer(AskStatus);
