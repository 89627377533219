import { computed, flow, makeObservable, observable, override } from 'mobx';
import { toast } from 'react-toastify';
import BaseEntity from './base_entity';

class ProjectNeed extends BaseEntity {
  /* eslint-disable */
  @observable title = '';
  @observable description = '';
  @observable status= ''
  @observable systemNeedId = ''
  projectId;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
  }

  @computed
  get params() {
    const params = {
      id: this.id,
      title: this.title,
      description: this.description,
      system_need_id: this.systemNeedId,
    };

    if (this.setForDelete) {
      params._destroy = true;
    }

    return params;
  }

  @computed
  get project() {
    return this.store.rootStore.projectStore.getById(this.projectId);
  }

  @computed
  get isActive() {
    return this.status === 'active';
  }

  @override
  destroy() {
    this.project.projectNeeds.splice(this.project.projectNeeds.indexOf(this));
  }

  @flow
  *toggleStatus() {
    try {
      const response = yield this.client.post(
        `/api/v1/helpables/${this.projectId}/${
          this.isActive ? 'pause_need' : 'unpause_need'
        }`,
        {
          need_id: this.id,
        }
      );

      if (response.data.success) {
        this.update({
          status: this.isActive ? 'pause' : 'active',
        });
        return;
      }

      toast.error('Could not complete this request at the moment');
    } catch (e) {
      console.log(e);
    }
  }
}

export default ProjectNeed;
