import React from 'react';

import { observer } from 'mobx-react';
import { Alert, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import useStore from '../../data/store';
import withProject from '../layouts/with_project';
import HelpableSettings from './settings';

const Settings = () => {
  const { projectStore } = useStore();
  const { account } = projectStore;
  const project = projectStore.activeProjectForEdit;
  const approvalRequested = !isEmpty(project.approvalRequestedAt);

  return (
    <div className="helpable-settings">
      {project.requiresApproval && !approvalRequested && (
        <Alert
          color="warning"
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <strong>Project approval required:</strong> Send it for approval
            once you are done with setting up the project
          </div>
          <Button onClick={() => project.sendToApproval()} color="warning">
            Send It For Approval
          </Button>
        </Alert>
      )}
      {project.requiresApproval && approvalRequested && (
        <Alert color={project.isRejected ? 'danger' : 'info'}>
          <div className="d-flex justify-content-between align-items-center">
            {project.isRejected ? (
              <div>
                <strong>Approval Declined:</strong> Project approval is rejected
                by the hub admin. You can resend it for approval.
              </div>
            ) : (
              <div>
                <strong>Awaiting Approval:</strong> Project has been sent for
                approval. You will be notified.
              </div>
            )}
            <div style={{ gap: 10, display: 'flex' }}>
              {project.isRejected && !account.isEcosystem && (
                <Button
                  onClick={() =>
                    confirmAlert({
                      title: 'Move to Helpbuild Ecosystem',
                      message:
                        'Confirm to move this project to pre approved helpbuild ecosystem.',
                      buttons: [
                        {
                          label: 'Confirm',
                          onClick: () => project.moveToEcosystem(),
                        },
                        {
                          label: 'Cancel',
                          onClick: null,
                        },
                      ],
                    })
                  }
                >
                  Move To Helpbuild Ecosystem
                </Button>
              )}
              <Button
                onClick={() => project.sendToApproval()}
                color={project.isRejected ? 'warning' : 'info'}
              >
                Resend For Approval
              </Button>
            </div>
          </div>
        </Alert>
      )}
      <HelpableSettings helpable={project} />
    </div>
  );
};

export default withProject(observer(Settings));
