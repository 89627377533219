import { map } from 'lodash';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

function SubscribedNeeds({ toggle, canToggle, needs, title }) {
  return (
    <div className="selected-needs-container">
      <h4>{title}</h4>
      <div className="selected-needs">
        {map(needs, need => (
          <Badge>
            {need.title}

            {canToggle && (
              <FontAwesomeIcon
                className="cursor-pointer"
                onClick={() => toggle(need.id)}
                icon={faTimes}
              />
            )}
          </Badge>
        ))}
      </div>
    </div>
  );
}

SubscribedNeeds.propTypes = {
  toggle: PropTypes.func.isRequired,
  needs: PropTypes.instanceOf(Array).isRequired,
  canToggle: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
export default observer(SubscribedNeeds);
