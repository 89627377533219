import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

const ProjectRequestLayout = ({ children }) =>
  children;
ProjectRequestLayout.propTypes = {
  children: PropTypes.node,
};

export default observer(ProjectRequestLayout);
