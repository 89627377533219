import { observer } from 'mobx-react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compact, isEmpty, trim } from 'lodash';

const InviteUsers = ({ share }) => {
  const [inputValue, setInputValue] = useState('');
  const [optionalMessage, setOptionalMessage] = useState();

  // Split the input value by new lines
  const lines = compact(inputValue.split('\n'));

  // Parse each line and create an array of objects
  const data = lines.map(line => {
    const [firstName, lastName, email] = line.split(',');
    return {
      first_name: trim(firstName),
      last_name: trim(lastName),
      email: trim(email),
    };
  });

  if (!share.inviteUsers) return null;

  const toggle = () => {
    setInputValue('');
    share.update({ inviteUsers: false });
  };

  const handleSubmit = event => {
    share.invite(data, optionalMessage).then(res => {
      if (res) {
        toggle();
      }
    });
  };

  return (
    <Modal isOpen toggle={toggle}>
      <ModalHeader>Invite Users</ModalHeader>
      <ModalBody>
        <div>
          <form>
            <Input
              type="textarea"
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
              placeholder="First Name, Last Name, Email"
              rows={4}
              cols={50}
            />
            <br />
            <Input
              type="textarea"
              value={optionalMessage}
              onChange={e => {
                setOptionalMessage(e.target.value);
              }}
              placeholder="Optional personal message to the users"
              rows={2}
              cols={50}
            />
          </form>

          {data && (
            <div className="mt-3">
              <h5>Users</h5>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isEmpty(data) ? (
                      <tr>
                        <td colSpan={3}>
                          Please fill in the input to add users
                        </td>
                      </tr>
                    ) : (
                      data.map(d => (
                        <tr>
                          <td>{d.first_name}</td>
                          <td>{d.last_name}</td>
                          <td>{d.email}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
        <div className="text-right">
          <Button onClick={handleSubmit} disabled={isEmpty(data)} type="submit">
            Invite Users
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

InviteUsers.propTypes = {
  share: PropTypes.instanceOf(Object).isRequired,
};

export default observer(InviteUsers);
