import React, { useState } from 'react';

import { Button, Input, Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { filter, find, includes, isDate, isObject, map } from 'lodash';
import { DateRangePicker } from 'react-date-range';
import InputRange from 'react-input-range';
import Select from 'react-select';
import moment from 'moment';
import {
  commonStyles,
  filterSelectStyles,
} from '../../helpers/sidebar_helpers';

export const SearchInput = ({ input, handleChange }) => (
  <Input
    placeholder={input.placeholder}
    name={input.name}
    onChange={handleChange}
    value={input.value}
    type="search"
  />
);

export const SelectTag = ({ input, handleChange }) => (
  <Input
    name={input.name}
    onChange={handleChange}
    value={input.value}
    type="select"
  >
    {map(input.options, (opt, i) => (
      <option key={i} value={opt.value}>
        {opt.label}
      </option>
    ))}
  </Input>
);

export const DateRange = ({ input, handleChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => setIsOpen(!isOpen);

  const dateLabel = () => {
    if (!isDate(input.value.from) && !isDate(input.value.to))
      return input.placeholder;

    const startDate = isDate(input.value.from)
      ? moment(input.value.from).format('MMM/DD/YYYY')
      : 'NA';

    const endDate = isDate(input.value.to)
      ? moment(input.value.to).format('MMM/DD/YYYY')
      : 'NA';

    return `${startDate} - ${endDate}`;
  };

  return (
    <div className="dt-range-pick">
      <Input
        value={dateLabel()}
        id={`date-range-picker-popover-${input.name}`}
        onClick={togglePopover}
        disabled={input.disabled}
      />
      {isDate(input.value.from) && (
        <Button
          color="link"
          onClick={() =>
            handleChange({
              target: {
                name: input.name,
                value: {
                  from: null,
                  to: null,
                },
              },
            })
          }
        >
          &times;
        </Button>
      )}
      <Popover
        className="no-max-width"
        toggle={togglePopover}
        isOpen={isOpen}
        target={`date-range-picker-popover-${input.name}`}
        trigger="legacy"
        placement="bottom"
      >
        <PopoverBody>
          <DateRangePicker
            ranges={[
              {
                startDate: input.value.from || new Date(),
                endDate: input.value.to || new Date(),
                key: input.name,
              },
            ]}
            onChange={d =>
              handleChange({
                target: {
                  name: input.name,
                  value: {
                    from: d[input.name].startDate,
                    to: d[input.name].endDate,
                  },
                },
              })
            }
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

export const InputRangeTag = ({ input, handleChange }) => (
  <InputRange
    maxValue={input.max || 99999}
    minValue={input.min || 0}
    step={input.step || 1}
    disabled={input.disabled || false}
    value={
      isObject(input.value)
        ? { min: input.value.min, max: input.value.max }
        : input.value
    }
    onChange={value =>
      handleChange({
        target: {
          name: input.name,
          value,
        },
      })
    }
  />
);

export const ReactSelectTag = ({ input, handleChange }) => (
  <Select
    options={input.options}
    styles={filterSelectStyles}
    value={
      input.isMulti
        ? filter(input.options, o => includes(input.value, o.value))
        : find(input.options, { value: input.value })
    }
    isMulti={input.isMulti}
    isDisabled={input.disabled}
    onChange={e => {
      handleChange({
        target: {
          name: input.name,
          value: input.isMulti ? map(e, v => v.value) : e.value,
        },
      });
    }}
  />
);

SelectTag.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
};

SearchInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
};

DateRange.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
};

InputRangeTag.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
};

ReactSelectTag.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
};
