import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import HelperActions from './_helper_actions';
import { renderFilter } from '../../helpers/filter_helpers';

const HelperDetail = ({ helper, basic = false }) => {
  const { userStore, projectStore } = useStore();
  if (isEmpty(helper)) return null;

  const permissionsAsOptions = [
    { label: 'Manage Project', value: 'manage_project' },
    { label: 'Manage Announcements', value: 'manage_announcements' },
    { label: 'Manage Needs', value: 'manage_needs' },
    { label: 'Manage Helpers', value: 'manage_helpers' },
    { label: 'Manage Asks', value: 'manage_asks' },
  ];

  const permissions = {
    name: 'permissions',
    type: 'react-select',
    placeholder: 'Select...',
    label: 'Helper Permissions',
    value: helper.permissions,
    isMulti: true,
    disabled: !projectStore.hasPermission('manage_asks'),
    options: permissionsAsOptions,
  };

  const handleChange = e => {
    helper.updatePermissions(e.target.value);
  };

  return (
    <Card className="helper-card">
      <CardBody>
        <CardTitle>
          <span className="mr-2">{helper.friendlyName}</span>
          {helper.userId === userStore.currentUser.id ? (
            <Badge className="badge-info">YOU</Badge>
          ) : (
            <span className={`text-${helper.statusClass} mr-2`}>
              <FontAwesomeIcon icon={helper.statusIcon} title={helper.status} />
            </span>
          )}
        </CardTitle>
        <p className="small">{helper.qualifyingStatement}</p>
        <p>
          {map(helper.subscribedNeedTitles, (n, i) => (
            <Badge key={i} className="mr-1" color="primary">
              {n}
            </Badge>
          ))}
        </p>
        <p>Permissions: {renderFilter(permissions, handleChange)}</p>
      </CardBody>
      <CardFooter className="p-3 text-right d-flex justify-content-end">
        <HelperActions record={helper} />
      </CardFooter>
    </Card>
  );
};

HelperDetail.propTypes = {
  helper: PropTypes.object.isRequired,
  basic: PropTypes.bool,
};

export default observer(HelperDetail);
