import { map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Col, Table } from 'reactstrap';

import useStore from '../../data/store';

const UsersList = ({ records }) => {
  const { impersonateUserStore } = useStore();
  return (
    <Col>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {map(records, (record, i) => (
              <tr key={i}>
                <td>{record.firstName}</td>
                <td>{record.lastName}</td>
                <td>{record.email}</td>
                <td>{record.status}</td>
                <td>
                  <Button
                    onClick={() =>
                      impersonateUserStore.startImpersonate(record.id)
                    }
                  >
                    Impersonate
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

UsersList.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
};

export default observer(UsersList);
