import React from 'react';

import { observer } from 'mobx-react';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import AnnouncementsFeed from './_feed';
import withProject from '../layouts/with_project';

const ActivitiesPage = () => (
  <div>
    <ThreeColumnLayout
      center={<AnnouncementsFeed />}
      right={<HelpersLeaderboard />}
    />
  </div>
);

export default withProject(observer(ActivitiesPage));
