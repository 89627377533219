import React, { useState } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarBrand,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Initial } from 'react-initial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import {
  faBars,
  faCalendarPlus,
  faLifeRing,
  faQuestion,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import ProjectSelector from '../modules/projects/selector';
import useStore from '../../data/store';
import Wallets from '../shared/_wallets';
import BasicForm from '../helpables/basic_form';
import Logo from './_logo.js';
import t from '../../t';
import NavIconLink from './header_nav_icon';
import HintedLabel from '../shared/_hinted_label';
import { redirectToAuth } from '../../routes/AuthenticatedRoute';
import VideoHelp from '../shared/_videoHelp';

export const creatableProject = {
  name: 'projects',
  component: BasicForm,
  modalTitle: 'Add new Project',
  size: 'md',
};

const _header = ({ forcePublic = false }) => {
  const {
    projectStore,
    appStore,
    impersonateUserStore,
    userStore,
  } = useStore();
  const [showActions, setShowActions] = useState(false);

  const { currentUser } = userStore;
  const { account } = projectStore;

  const isPublic =
    forcePublic || (isEmpty(currentUser) && !projectStore.hasActiveProject);

  const handleFeedBack = () => {
    const howuOverlay = document.getElementsByClassName('howu-overlay')[0];
    const howukuContainer = document.getElementsByClassName(
      'howuku-container'
    )[0];

    if (howukuContainer && howuOverlay) {
      howuOverlay.style.display = 'block';
      howukuContainer.style.display = 'block';
    }
  };

  const userNav = () => {
    if (userStore.userSignedIn) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            className="d-flex align-items-center"
            nav
            caret={false}
          >
            <Initial
              style={{ borderRadius: '100%' }}
              width={36}
              height={36}
              fontSize={14}
              useWords
              charCount={2}
              name={currentUser.fullName}
              title={currentUser.fullName}
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem disabled>
              <div className="text-black font-weight-bolder">
                {currentUser.fullName}
              </div>
              {currentUser.accountName}
            </DropdownItem>
            <DropdownItem disabled className="tour-user-wallet">
              <Wallets walletable={currentUser} />
            </DropdownItem>
            <DropdownItem
              tag={Link}
              to="/user"
              className="tour-user-account-link"
            >
              {t('header.manage_user_account')}
            </DropdownItem>
            <DropdownItem divider />
            {!isEmpty(account) && account.canManage && (
              <>
                <DropdownItem tag={Link} to="/settings">
                  {t('header.manage_hub')}
                </DropdownItem>
              </>
            )}
            {currentUser.isSystem && (
              <>
                <DropdownItem
                  tag={Link}
                  to="/platform_admin"
                  className="font-weight-bold text-danger"
                >
                  {t('header.manage_ecosystem')}
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            {currentUser.impersonating && (
              <>
                <DropdownItem
                  onClick={() => impersonateUserStore.stopImpersonate()}
                  className="tour-user-account-link"
                >
                  <FontAwesomeIcon icon={faUserSecret} />{' '}
                  {t('header.stop_impersonating')}
                </DropdownItem>
              </>
            )}

            <DropdownItem tag="a" href="/sign_out">
              {t('header.signout')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return (
      <div className="project-actions">
        <NavIconLink
          className="sign-in"
          icon="sign-in-alt"
          onClick={redirectToAuth}
          title={t(`common.signin`)}
        />
      </div>
    );
  };
  const helpNav = () => (
    <UncontrolledDropdown nav inNavbar className="mr-2 ml-5">
      <DropdownToggle className="d-flex align-items-center" nav caret={false}>
        <Button
          color="primary text-light "
          title="Get help"
          style={{
            borderRadius: '100%',
            width: 36,
            height: 36,
            fontSize: 14,
            padding: 0,
          }}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
      </DropdownToggle>
      <DropdownMenu right className="get-help-bg">
        <DropdownItem disabled>
          <div>{t(`header.videos`)}</div>
        </DropdownItem>
        <DropdownItem>
          <HintedLabel
            className="small"
            helpTextKey="what_is_helpbuild"
            video
          />
        </DropdownItem>
        <DropdownItem>
          <HintedLabel className="small" helpTextKey="create_project" video />
        </DropdownItem>
        <DropdownItem>
          <HintedLabel className="small" helpTextKey="create_video" video />
        </DropdownItem>
        <DropdownItem divider />

        <DropdownItem disabled>
          <div>{t(`header.get_help`)}</div>
        </DropdownItem>
        <DropdownItem
          data-cal-namespace=""
          data-cal-link="helpbuild/webinar"
          data-cal-config='{"layout":"month_view"}'
        >
          <div className="text-black">
            <FontAwesomeIcon className="mr-2" icon={faTv} />
            {t(`header.book_webinar`)}
          </div>
        </DropdownItem>
        <DropdownItem
          data-cal-namespace=""
          data-cal-link="helpbuild/help"
          data-cal-config='{"layout":"month_view"}'
        >
          <div>
            <FontAwesomeIcon className="mr-2" icon={faCalendarPlus} />
            {t(`header.book_call`)}
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleFeedBack}>
          <div className="text-info">
            <FontAwesomeIcon className="mr-2" icon={faLifeRing} />
            {t(`header.leave_feedback`)}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
  return (
    <>
      <header className="sticky-header">
        <Container>
          <Row>
            <NavbarBrand>
              <Logo />
            </NavbarBrand>
            {(projectStore.account.status === 'active' ||
              (!isPublic && !isEmpty(projectStore.activeProject))) && (
              <div className="project-selector-and-actions">
                {projectStore?.activeProject?.anonymized && (
                  <div
                    className="small mr-2 rounded "
                    style={{
                      border: '1px solid white',
                      backgroundColor: 'white',
                      color: 'red',
                      padding: '3px',
                    }}
                  >
                    <FontAwesomeIcon
                      icon="user-secret"
                      title="This project is anonymous"
                    />
                  </div>
                )}
                {userStore.userSignedIn && (
                  <>
                    <ProjectSelector
                      showActions={showActions}
                      toggleActions={() => setShowActions(false)}
                    />
                    <Button
                      onClick={() => setShowActions(!showActions)}
                      className="action-toggler"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                  </>
                )}
              </div>
            )}
            {userStore.userSignedIn ? (
              <Nav
                className="flex-row justify-content-end tour-user-bits"
                navbar
              >
                {(currentUser.confirmedProjects > 0 ||
                  projectStore.account.hasAccess) && (
                  <div className="project-actions">
                    {projectStore.isInProjectView() && (
                      <NavIconLink
                        className="tour-hub-link"
                        icon="network-wired"
                        link="/"
                        title={t(`header.hub`)}
                      />
                    )}
                    {projectStore.account.status === 'active' &&
                      currentUser.confirmedProjects > 0 &&
                      (projectStore.account.hasAccess ||
                        projectStore.account.hasAccessPublic) && (
                        <NavIconLink
                          className="tour-activities-link"
                          icon="plus"
                          link="#"
                          title={t(`header.add_project`)}
                          id="add-project-btn"
                          onClick={() => {
                            appStore.initiateCreatable(creatableProject);
                          }}
                          disabled={
                            projectStore.hasActiveProject &&
                            !projectStore.activeProject.persisted
                          }
                        />
                      )}
                  </div>
                )}
                {helpNav()}
                {userNav()}
              </Nav>
            ) : (
              <Nav
                className="flex-row justify-content-end tour-user-bits"
                navbar
              >
                {userNav()}
              </Nav>
            )}
          </Row>
        </Container>
      </header>
      {appStore.showVideoHelp && <VideoHelp />}
    </>
  );
};

_header.propTypes = {
  forcePublic: PropTypes.bool,
};

export default observer(_header);
