import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { isEmpty, map } from 'lodash';
import ReactQuill from 'react-quill';
import { basicEditorToolbar } from '../../../helpers/shared_helpers';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-mention';
import 'react-quill/dist/quill.bubble.css';

const RichEditor = ({
  onChange,
  label,
  value,
  mentions,
  placeholder,
  toolbar,
  className,
  readOnly = false,
}) => (
  <div className={`${className}`}>
    {!isEmpty(label) && <Label>{label}</Label>}
    <ReactQuill
      theme={readOnly ? 'bubble' : 'snow'}
      readOnly={readOnly}
      modules={{
        toolbar,
        'emoji-toolbar': true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          source: useCallback((searchTerm, renderItem) => {
            const values = map(mentions, s => ({
              value: s.name,
              id: s.id,
            }));

            if (searchTerm.length === 0) {
              renderItem(values, searchTerm);
            } else if (values) {
              renderItem(
                values.filter(v =>
                  v.value.toLowerCase().includes(searchTerm.toLowerCase())
                ),
                searchTerm
              );
            }
          }, []),
        },
      }}
      value={value}
      onChange={e => onChange(e)}
      placeholder={placeholder}
    />
  </div>
);

RichEditor.defaultProps = {
  onChange: () => {},
  label: '',
  mentions: [],
  toolbar: basicEditorToolbar,
  className: '',
  readOnly: false,
};

RichEditor.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  mentions: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  toolbar: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default RichEditor;
