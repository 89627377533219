import AskForm from '../views/asks/Form';

export const creatableAsk = {
  name: 'asks',
  component: AskForm,
  modalTitle: 'Add new Ask',
};

const controlStyle = {
  paddingLeft: 15,
  backgroundColor: '#c662e0',
  color: '#fff',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 10,
};

export const commonStyles = {
  placeholder: provided => ({
    ...provided,
    color: '#ffffff',
  }),
  input: (provided, st) => ({
    ...provided,
    color: '#fff',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#ffffff',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#57B8D7' : '#1f252b',
    color: '#ffffff',
    paddingLeft: 20,
    fontSize: 14,
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#1f252b',
    borderRadius: 15,
    overflow: 'hidden',
    padding: 0,
  }),
};

export const customStylesAlt = {
  ...commonStyles,
  control: (provided, st) => ({
    ...provided,
    ...controlStyle,
  }),
};

export const customStyles = {
  ...commonStyles,
  control: (provided, st) => ({
    ...provided,
    ...controlStyle,
  }),
};

export const filterSelectStyles = {
  ...commonStyles,
  input: (provided, st) => ({
    ...provided,
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.9)',
  }),
  control: provided => ({
    ...provided,
    paddingLeft: 10,
    borderRadius: 10,
    background: 'transparent',
    fontSize: 13,
    minHeight: 40,
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  }),
};
