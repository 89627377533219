import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { isEmpty, isNull, map, tail } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import ShareEntity from '../../data/entities/share';
import notification from '../../data/concerns/notfications';
import InviteUsers from './_invite_users';
import useStore from '../../data/store';
import HelpText from '../shared/_help_text';
import HintedLabel from '../shared/_hinted_label';
import t from '../../t';
import VideoUploaderWithUrl from '../shared/_video_uploader_with_url';

const Share = ({ shareable }) => {
  const { userStore } = useStore();
  const [share, setShare] = useState(null);

  useEffect(() => {
    shareable.fetchShares();
  }, []);

  const initializeShare = () => {
    setShare(
      new ShareEntity(
        {
          name: '',
          shareable_id: shareable.id,
          shareable_type: shareable.helpableType,
        },
        userStore
      )
    );
  };

  const handleDelete = s => {
    confirmAlert({
      title: 'Delete Share Link?',
      message:
        'Are you sure you want to delete this link? all analytics with this link will be lost.',
      buttons: [
        {
          label: 'Delete',
          onClick: () =>
            s.destroyOnServer().then(res => {
              if (res) {
                shareable.shares.splice(shareable.shares.indexOf(s));
              }
            }),
        },
        { label: 'Cancel' },
      ],
    });
  };

  const shareActions = s => (
    <UncontrolledDropdown size="xs" group>
      <Button className="btn btn-xs xx-small btn-block">
        <CopyToClipboard
          text={`${window.location.origin}/shares/${s.id}`}
          onCopy={() =>
            notification.notifySuccess(
              'Project share link copied. Send in emails, post online and get your network to come help'
            )
          }
        >
          <a href="#">Copy URL</a>
        </CopyToClipboard>
      </Button>
      <DropdownToggle split color="primary" />
      <DropdownMenu className="btn-xs">
        <DropdownItem
          onClick={() => handleDelete(s)}
          className="text-danger ml-1"
        >
          Delete
        </DropdownItem>
        <DropdownItem onClick={() => setShare(s)} className="text-info ml-1">
          Edit
        </DropdownItem>
        <DropdownItem
          onClick={() => s.update({ inviteUsers: true })}
          className="text-info ml-1"
        >
          Invite Users
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderBody = () => {
    if (shareable.fetchingShares)
      return (
        <tr>
          <td className="text-center" colSpan={5}>
            Fetching...Please wait
          </td>
        </tr>
      );

    return map(shareable.shares, (s, i) => (
      <tr key={i}>
        <td>
          <Link to={`${shareable.sharesPath}/${s.id}`}>{s.name}</Link>
          <div className="mt-2 xx-small">
            <div>
              <Label>Share URL:</Label>
              <div className="font-weight-bolder">{`${window.location.origin}/shares/${s.id}`}</div>
            </div>
            {!isEmpty(s.optionalMessage) && (
              <div className="mt-2">
                <Label>Optional Message:</Label>
                <div>{s.optionalMessage}</div>
              </div>
            )}
            {!isEmpty(s.videoUrl) && (
              <div className="mt-2">
                <Label>Optional VideoURL:</Label>
                <div>{s.videoUrl}</div>
              </div>
            )}
            {!isEmpty(s.projectHelper?.user?.fullName) && (
              <div className="mt-2">
                <Label>Created By:</Label>
                <div>{s.projectHelper?.user?.fullName}</div>
              </div>
            )}
          </div>
        </td>
        <td className=" xx-small">{s.createdAt.format('DD-MM-YYYY hh:mma')}</td>
        <td className=" xx-small">
          {s.maxUsers >= s.unlimitedUsersThreshold
            ? 'more than 100'
            : s.maxUsers}
        </td>
        <td>{s.visitCount || 0}</td>
        <td>{s.userCount || 0}</td>
        <td className="xx-small font-weight-bolder">
          {shareActions(s)}
          <div className="d-flex flex-wrap"></div>
        </td>
        <InviteUsers share={s} />
      </tr>
    ));
  };

  const helperValues = () => ({
    '1 to 10': 10,
    '11 to 50': 50,
    '51 to 100': 100,
    'more than 100': share.unlimitedUsersThreshold,
  });

  return (
    <div className="share-project">
      <div className="text-right mb-3">
        <Button
          onClick={() => initializeShare()}
          color="primary text-white tour-create_share-link"
        >
          Create Share Link
        </Button>
      </div>
      {!isEmpty(shareable.shares) && (
        <Table responsive>
          <thead>
            <tr className="xx-small">
              <th>Name</th>
              <th>Created At</th>
              <th>Expected Joiners</th>
              <th>Visits</th>
              <th>Joiners</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </Table>
      )}
      {isEmpty(shareable.shares) && (
        <div className="text-center">
          <strong className="text-muted">No Share Links Created Yet</strong>
        </div>
      )}
      <Modal size="lg" isOpen={!isNull(share)} toggle={() => setShare(null)}>
        <ModalHeader toggle={() => setShare(null)}>
          Create Share Link
        </ModalHeader>
        {!isEmpty(share) && (
          <ModalBody>
            <Row>
              <Col>
                <FormGroup className="tour-share-name">
                  <HintedLabel helpTextKey="share_name" />
                  <Input
                    value={share.name}
                    onChange={e => share.update({ name: e.target.value })}
                  />
                </FormGroup>

                <FormGroup>
                  <HintedLabel helpTextKey="share_optional_message" />
                  <Input
                    type="textarea"
                    value={share.optionalMessage}
                    className="tour-share-message"
                    onChange={e =>
                      share.update({ optionalMessage: e.target.value })
                    }
                  />
                </FormGroup>

                <FormGroup className="tour-max-helpers">
                  <HintedLabel helpTextKey="share_max_helpers" />
                  {/*  add a select input with values '1 to 10', 10-50, 51 to 100, more than 100 */}
                  <Input
                    type="select"
                    name="maxUsers"
                    onChange={e => share.update({ maxUsers: e.target.value })}
                    value={share.maxUsers}
                  >
                    {Object.entries(helperValues()).map(([key, val]) => (
                      <option key={key} value={val}>
                        {key}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="tour-share-video">
                  <HintedLabel helpTextKey="share_link_video" />
                  <VideoUploaderWithUrl
                    attachable={share}
                    removeAttachment={() =>
                      share.update({
                        file: null,
                        thumbnails: [],
                        thumbnail: '',
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              onClick={() => {
                if (share.persisted) {
                  share.save().then(res => {
                    if (!res) return;

                    setShare(null);
                  });
                  return;
                }

                share.create().then(res => {
                  if (!res) return;

                  shareable.shares.push(share);

                  setShare(null);
                });
              }}
              color="primary"
              disabled={isEmpty(share.name.trim())}
            >
              {share.persisted ? 'Update' : 'Create'}
            </Button>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

Share.propTypes = {
  shareable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Share);
