import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import {
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  Form as ReactForm,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { observer } from 'mobx-react';
import t from '../../t';
import HintedLabel from '../shared/_hinted_label';
import { SelectTag } from '../shared/form_components';
import GiveBonus from './_give_bonus';

const Helpers = ({ ask }) => {
  if (isEmpty(ask.actionedAsks)) {
    return (
      <div className="ask-helpers pt-3">
        <h5>No helpers yet</h5>
      </div>
    );
  }

  return (
    <div className="ask-helpers table-responsive pt-3">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {map(ask.actionedAsks, a => (
            <tr>
              <td>{a.friendlyName}</td>
              <td>
                <Badge color={a.statusClass}>
                  {t(`ask.status.${a.status.toLowerCase()}.label`)}
                </Badge>
              </td>
              <td>
                {a.inReview && (
                  <UncontrolledButtonDropdown size="sm" group>
                    <Button
                      className="actionbtn"
                      id="caret"
                      color="success"
                      onClick={() => a.approve()}
                    >
                      Approve
                    </Button>
                    <DropdownToggle className="border-0" caret />
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => a.decline()}
                        className="small"
                      >
                        Decline
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
                {a.completed && (
                  <GiveBonus
                    fromWalletId={ask.project.wallet.id}
                    toWalletId={a.walletId}
                    defaultMessage="Bonus for successful completion of ask"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

Helpers.propTypes = {
  ask: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Helpers);
