import { extendObservable, flow, makeObservable, observable } from 'mobx';
import { isEmpty, map } from 'lodash';

import BaseStore from './base_store';
import WalletEntry from '../entities/wallet_entry';
import Filterable from '../concerns/filterable';

class WalletEntryStore extends BaseStore {
  @observable wallet_id = null;

  constructor(store) {
    super(store, WalletEntry);

    extendObservable(this, Filterable);

    this.initializeFilterable({
      created_at: { from: null, to: null },
      amount: { min: 1, max: 9999 },
    });

    makeObservable(this);
  }

  @flow
  *fetch(pms) {
    if (isEmpty(this.wallet_id)) return;
    const response = yield this.client.get(
      `/api/v1/wallets/${this.wallet_id}/entries.json`,
      {
        params: pms,
      }
    );

    if (response.data.success) {
      return this.update({
        records: map(response.data.entries, entry => this.addRecord(entry)),
        fetched: true,
      });
    }

    this.update({ fetched: true });
  }

  @flow
  *giveBonus(fromWalletId, params) {
    const response = yield this.client.post(
      `/api/v1/wallets/${fromWalletId}/give_bonus`,
      params
    );

    try {
      this.notifySuccess('Bonus has been sent successfully..reloading');
      // wait 3 seconds and then reload the page
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      return true;
    } catch (e) {
      this.notifySuccess('Transaction failed. Contact admin incase of debit');

      return false;
    }
  }
}

export default WalletEntryStore;
