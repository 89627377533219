import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isObject, map } from 'lodash';

import en from '../../config/locales/en.yml';

const checkForDynamicValues = data => {
  let updatedData = data;

  if (isObject(data)) {
    map(Object.keys(updatedData), k => {
      updatedData[k] = checkForDynamicValues(updatedData[k]);
    });

    return updatedData;
  }

  updatedData ||= '';
  updatedData = updatedData.replaceAll('%', '');
  updatedData = updatedData.replaceAll('{', '{{');
  updatedData = updatedData.replaceAll('}', '}}');

  return updatedData;
};

const createLocaleData = (data, key) => ({
  translation: checkForDynamicValues(data[key]),
});

const resources = {
  en: createLocaleData(en, 'en'),
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n.t;
