import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pluralize from 'react-pluralize';
import { amountFormatter } from '../../helpers/shared_helpers';
import useStore from '../../data/store';

const Wallets = ({ walletable }) => {
  const { appStore } = useStore();

  return (
    <div className="small font-weight-bold">
      <FontAwesomeIcon icon="coins" className="mr-1" />
      {amountFormatter(walletable.balance)}{' '}
      <Pluralize
        singular="token"
        count={walletable.balance}
        showCount={false}
      />
    </div>
  );
};

Wallets.propTypes = {
  walletable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Wallets);
