import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import JoinWrapper from './wrapper';

function Join({ match }) {
  return <JoinWrapper match={match} helpableType="project" />;
}

Join.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Join);
