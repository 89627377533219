import React from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Label,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import t from '../../t';
import useStore from '../../data/store';
import WrappedHTML from './_wrapped_html';

const HintedLabel = ({
  helpTextKey,
  className,
  error,
  video,
  title,
  button,
  description,
}) => {
  const { appStore } = useStore();
  const id = `label_${helpTextKey}`;
  const errorId = `${id}_error`;

  const showVideoHelp = url => {
    appStore.update({ videoHelpKey: helpTextKey, showVideoHelp: true });
  };

  const hTitle = isEmpty(title) ? t(`help.${helpTextKey}.label`) : title;
  const hDescription = isEmpty(description)
    ? t(`help.${helpTextKey}.text`)
    : description;
  return (
    <div className="mb-1">
      {!button && (
        <Label
          id={id}
          tag="span"
          className={classnames(`hint-label ${className}`)}
        >
          {video && (
            <a
              href="#"
              onClick={() =>
                showVideoHelp(
                  t(`help.${helpTextKey}.video`) ||
                    'https://wwx-general.b-cdn.net/helpbuild/placeholder.webm'
                )
              }
            >
              {hTitle}
            </a>
          )}
          {!video && (
            <span>
              {hTitle}
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="ml-1"
                id={`trigger_${id}`}
              />
            </span>
          )}
        </Label>
      )}
      {button && (
        <Button
          id={id}
          color="link"
          className={classnames(`hint-label ${className}`)}
          onClick={() =>
            showVideoHelp(
              t(`help.${helpTextKey}.video`) ||
                'https://wwx-general.b-cdn.net/helpbuild/placeholder.webm'
            )
          }
        >
          {hTitle}
        </Button>
      )}

      {error && (
        <>
          <Button color="link p-0 ml-1 text-danger medium" id={errorId}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Button>
          <UncontrolledTooltip
            target={errorId}
            className="bg-danger text-white"
          >
            {t(`help.${helpTextKey}.error`)}
          </UncontrolledTooltip>
        </>
      )}
      {!video && (
        <UncontrolledPopover
          trigger="focus"
          placement="auto"
          target={`trigger_${id}`}
        >
          <PopoverHeader>{hTitle}</PopoverHeader>
          <PopoverBody>
            <WrappedHTML content={hDescription} />
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </div>
  );
};

HintedLabel.defaultProps = {
  className: '',
  error: false,
  button: false,
  video: false,
  title: null,
  description: null,
};

HintedLabel.propTypes = {
  helpTextKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  button: PropTypes.bool,
  video: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default observer(HintedLabel);
