import React from 'react';

import { observer } from 'mobx-react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilteredList from '../modules/filtered_list';
import useStore from '../../data/store';
import Ask from './_ask';
import AsksFeed from './_asks_feed';
import { creatableAsk } from '../../helpers/sidebar_helpers';

const AsksIndex = () => {
  const { askStore, projectStore, appStore } = useStore();
  const askFilters = [
    {
      label: 'List Type',
      name: 'ownership',
      type: 'select',
      placeholder: 'Select...',
      size: 6,
      options: [
        { label: 'All Asks', value: '' },
        { label: "Asks I'm working on", value: 'mine' },
        { label: 'Asks I added', value: 'owner' },
      ],
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      size: 6,
      placeholder: 'Select...',
      options: [
        { label: 'All', value: '' },
        { label: 'Completed', value: 'completed' },
        { label: 'Needs Review', value: 'review' },
        { label: 'In Progress', value: 'started' },
        { label: 'Not Started', value: 'pending' },
        { label: 'Expired', value: 'expired' },
      ].concat(
        projectStore.activeProject.canManageAsks
          ? [
              {
                label: 'Draft',
                value: 'draft',
              },
            ]
          : []
      ),
    },
    {
      label: 'Needs',
      name: 'needs',
      type: 'react-select',
      placeholder: 'Select...',
      size: 12,
      isMulti: true,
      options: projectStore.currentUserSubscribedProjectNeeds,
    },
    // {
    //   name: 'name',
    //   type: 'search',
    //   placeholder: 'Search...',
    // },
  ];

  return (
    <Card className="h-auto asks-feed">
      <CardHeader className="d-flex justify-content-between ">
        <div>
          <FontAwesomeIcon className="mr-2" icon="briefcase" />
          <strong>Asks</strong>
        </div>
        <div className="d-flex">
          {projectStore.hasPermission('manage_asks') && (
            <span className="ml-2">
              <Button
                className="btn btn-primary btn-sm rounded-circle"
                color="primary"
                onClick={() => appStore.initiateCreatable(creatableAsk)}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </span>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <FilteredList
          gridComponent={Ask}
          listComponent={AsksFeed}
          store={askStore}
          filters={askFilters}
          changeTrigger={projectStore.activeProjectId}
          recordClassName="col-xl-4"
        />
      </CardBody>
    </Card>
  );
};

export default observer(AsksIndex);
