import React from 'react';

import { observer } from 'mobx-react';

const NotFound = () => {
  return (
    <div>We are sorry, but we cannot find the page you are looking for.</div>
  );
};

export default observer(NotFound);
