import React from 'react';
import { observer } from 'mobx-react';

import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';

import useStore from '../../data/store';
import HelpableSettings from '../settings/settings';

function HubAdminPage() {
  const { projectStore } = useStore();
  const { account } = projectStore;

  if (isEmpty(account)) return null;
  if (!account.canManage) return <Redirect to="/" />;

  return (
    <div className="helpable-settings">
      <HelpableSettings helpable={account} />
    </div>
  );
}

export default observer(HubAdminPage);
