import React from 'react';

import { observer } from 'mobx-react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, map, startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import t from '../../t';
import useStore from '../../data/store';
import Player from '../modules/player';

const ProjectCard = () => {
  const { projectStore } = useStore();
  const subscribedNeedIds = Array.from(
    projectStore.activeProject.myProjectHelper.subscribedNeedIds
  );

  const formatValue = value => value;

  const getStatCards = () => {
    const stats = [];
    map(Object.keys(projectStore.activeProject.stats), (stat, i) =>
      stats.push(
        <Badge className="badge-light info h-auto mb-2 xx-small p-2" key={i}>
          <span>{t(`projects.labels.${stat}`)} : </span>
          {formatValue(projectStore.activeProject.stats[stat].total, stat)}
        </Badge>
      )
    );
    return stats;
  };

  const needs = () => {
    const seeAllNeeds = projectStore.hasPermission('manage_needs');
    return projectStore.activeProject.needs.map((n, idx) => {
      const isSubscribed = seeAllNeeds || subscribedNeedIds.includes(n.id);
      return (
        <p key={idx}>
          <span>
            {isSubscribed && (
              <>
                <FontAwesomeIcon
                  id={`need-${idx}-joined`}
                  icon={faCheckSquare}
                  className="mr-1"
                />
                <UncontrolledTooltip target={`need-${idx}-joined`}>
                  {t('projects.tooltips.helping')}
                </UncontrolledTooltip>
              </>
            )}
            {startCase(n.title)}
          </span>
        </p>
      );
    });
  };

  return (
    <Card className="project-card">
      <CardHeader>
        <CardTitle className="d-flex justify-content-between">
          <div className="d-flex justify-content-start align-items-center">
            <span className="mr-2">
              <FontAwesomeIcon icon="rocket" />
            </span>
            {t('projects.labels.project_summary')}
          </div>
          {projectStore.hasPermission('manage_project') && (
            <div>
              <Link
                to={`/p/${projectStore.activeProject.id}/settings`}
                className="text-black-50 pt-1"
              >
                <FontAwesomeIcon icon="cog" />
              </Link>
            </div>
          )}
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-2">
        {projectStore.hasActiveProject &&
          projectStore.hasPermission('manage_helpers') &&
          projectStore.activeProject.hasRequests && (
            <div className="mb-2 mt-1 text-center">
              <Link
                className="font-weight-bold text-danger"
                to={`/p/${projectStore.activeProject.id}/settings?tab=4`}
              >
                {t('projects.labels.requests_to_join')}
              </Link>{' '}
            </div>
          )}
        <Player
          url={projectStore.activeProject.videoUrlToShow}
          controls="lite"
        />

        <div className="project-details mt-1 created-by">
          <span className="subtitle">{t('projects.labels.created_by')}:</span>
          <span className="subtitle text-dark">
            {projectStore.activeProject.createdBy.name}
          </span>
        </div>
        <div className="project-details mt-1 project-status-announcement">
          <span className="subtitle">
            {t('projects.labels.last_project_status')}:
          </span>
          <span className="subtitle text-dark">
            {' '}
            {isEmpty(projectStore.activeProject.lastProjectAnnouncement) &&
              t('projects.labels.none_yet')}
            {!isEmpty(projectStore.activeProject.lastProjectAnnouncement) && (
              <>
                <Link
                  to={`/p/${projectStore.activeProject.id}/announcements/${projectStore.activeProject.lastProjectAnnouncement.id}`}
                >
                  {moment(
                    projectStore.activeProject.lastProjectAnnouncement.createdAt
                  ).format('MMMM Do YYYY')}
                </Link>
              </>
            )}
          </span>
        </div>
        <div className="project-details mt-1 tour-project-stats stats">
          <span className="subtitle">{t('projects.labels.stats')}:</span>
          <div className="stats-badge">{getStatCards()}</div>
        </div>
        <div className="project-details mt-1">
          <span className="subtitle">
            {t('projects.labels.needs_help_with', {
              count: projectStore.activeProject.needs.length,
            })}
            :
          </span>
          <div className="pd-info">{needs()}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export default observer(ProjectCard);
