import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import FilteredList from '../modules/filtered_list';
import Announcement from '../../data/entities/announcement';
import AnnouncementListing from './_announcement_listing';
import { creatableAsk } from '../../helpers/sidebar_helpers';
import AnnouncementBox from './_announcement_box';
import t from '../../t';

const AnnouncementsFeed = () => {
  const { announcementStore, projectStore } = useStore();

  useEffect(() => {}, []);

  return (
    <>
      <Card className="h-auto asks-feed">
        <CardHeader className="d-flex justify-content-between ">
          <div>
            <FontAwesomeIcon className="mr-2" icon="bullhorn" />
            <strong>{t('header.announcements')}</strong>
          </div>
          <div className="d-flex">
            {(projectStore.hasPermission('manage_project') ||
              projectStore.hasPermission('manage_needs')) && (
              <span className="ml-2">
                <AnnouncementBox icon project={projectStore.activeProject} />
              </span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <FilteredList
            gridComponent={Announcement}
            listComponent={AnnouncementListing}
            store={announcementStore}
            filters={[]}
            changeTrigger={projectStore.activeProjectId}
            recordClassName="col-xl-4"
          />
        </CardBody>
      </Card>
    </>
  );
};

export default observer(AnnouncementsFeed);
