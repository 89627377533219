import { Col, FormGroup, Input, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import HintedLabel from '../shared/_hinted_label';
import Checkbox from '../shared/_checkbox';
import t from '../../t';
import useStore from '../../data/store';

const OtherSettingsForm = ({ helpable }) => {
  const { appStore, userStore } = useStore();
  const getField = key => {
    if (
      key.startsWith('ecosystem') &&
      !(appStore.isEcosystemHub() && userStore.currentUser.isSystem)
    ) {
      return null;
    }
    if (typeof helpable.settings[key] === 'boolean')
      return (
        <Checkbox
          onChange={m => helpable.updateSetting(key, m.target.checked)}
          checked={helpable.settings[key] === true}
          disabled={false}
        />
      );
    // if the key includes 'password' then it is a password field
    if (key.includes('password'))
      return (
        <Input
          name={key}
          onChange={m => helpable.updateSetting(key, m.target.value)}
          value={helpable.settings[key]}
          type="password"
        />
      );
    if (typeof helpable.settings[key] === 'number')
      return (
        <Input
          name={key}
          onChange={m => helpable.updateSetting(key, m.target.value)}
          value={helpable.settings[key]}
          type="number"
        />
      );
    // check the values from the translation and if it has array elements then it is a select field
    if (['access', 'visibility', 'project_approval'].includes(key)) {
      return (
        <Input
          type="select"
          name={key}
          onChange={m => helpable.updateSetting(key, m.target.value)}
          value={helpable.settings[key]}
        >
          {t(`help.${key}.values`)
            .split(',')
            .map(option => (
              <option key={option} value={option.trim()}>
                {t(`common.${option.trim()}`)}
              </option>
            ))}
        </Input>
      );
    }
    return (
      <Input
        name={key}
        onChange={m => helpable.updateSetting(key, m.target.value)}
        value={helpable.settings[key]}
        placeholder={t(`help.${key}.placeholder`)}
      />
    );
  };

  const getFieldAndLabel = key => {
    const field = getField(key);
    if (isEmpty(field)) return null;
    return (
      <Col sm={4}>
        <FormGroup key={key}>
          <HintedLabel helpTextKey={`${key}`} />
          {field}
        </FormGroup>
      </Col>
    );
  };

  return (
    <div className="ml-2 mb-5">
      <hr />
      {/* {helpable.isAccount && ( */}
      <Row>
        {/* loop through the settings object and add a formgroup for each, the key is also used for the translation to get labels */}
        {Object.keys(helpable.settings).map(key => getFieldAndLabel(key))}
      </Row>
    </div>
  );
};

OtherSettingsForm.propTypes = {
  helpable: PropTypes.object.isRequired,
};

export default observer(OtherSettingsForm);
