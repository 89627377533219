import { extendObservable, flow, makeObservable, observable } from 'mobx';
import { map } from 'lodash';

import BaseStore from './base_store';
import Filterable from '../concerns/filterable';
import User from '../entities/user';

class ShareUserStore extends BaseStore {
  @observable share;

  constructor(store, share) {
    super(store, User);

    extendObservable(this, Filterable);

    this.initializeFilterable({
      query: '',
      status: '',
    });

    this.share = share;

    makeObservable(this);
  }

  @flow
  *fetch(pms) {
    try {
      const response = yield this.client.get(
        `/api/v1/shares/${this.share.id}/users.json`,
        {
          params: {
            ...(pms || {}),
            shareable_type: this.share.shareableType,
            shareable_id: this.share.shareableId,
          },
        }
      );

      this.update({
        records: [
          ...this.records,
          ...map(response.data.users, entry => this.addRecord(entry)),
        ],
        fetched: true,
      });

      this.pagination.update(response.data.pagination);
    } catch (e) {
      this.update({ fetched: true });
    }
  }
}

export default ShareUserStore;
