import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';

import { toast } from 'react-toastify';
import moment from 'moment';
import BaseEntity from './base_entity';
import ProjectHelper from './project_helper';
import ShareUserStore from '../stores/share_user_store';
import Videoable from '../concerns/videoable';

class Share extends BaseEntity {
  /* eslint-disable */
  @observable name = '';
  @observable visitCount = 0;
  @observable userCount = 0;
  @observable projectHelper;
  @observable createdAt;
  @observable creating = false
  @observable shareableType;
  @observable shareableId;
  @observable maxUsers = 100;
  @observable inviteUsers = false;
  @observable optionalMessage = '';
  @observable users;
  unlimitedUsersThreshold = 999;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);
    extendObservable(this, Videoable);
    this.users = new ShareUserStore(store.rootStore, this);
    this.handleConstruction(value);
  }

  @computed
  get shareLink() {
    return `/p/${this.shareableId}/share/${this.id}`;
  }

  @computed
  get params() {
    return {
      name: this.name,
      max_users: this.maxUsers,
      optional_message: this.optionalMessage,
      shareable_type: this.shareableType,
      shareable_id: this.shareableId,
    };
  }

  @computed
  get basicParams() {
    return this.params;
  }

  @action
  handleConstruction(value) {
    this.json = value;
    this.dirty = false;

    const val = { ...value };

    if (val.project_helper) {
      this.projectHelper = new ProjectHelper(val.project_helper, this.store);

      delete val.project_helper;
    }

    if (val.created_at) {
      this.createdAt = moment(val.created_at);

      delete val.created_at;
    }

    this.initialize(val);
  }

  @flow
  *create() {
    try {
      const response = yield this.client.post(
        `/api/v1/shares`,
        this.formDataParams()
      );

      this.handleConstruction(response.data.share);
      return true;
    } catch (e) {
      toast.error(e.data?.message);
      return false;
    }
  }

  @flow
  *invite(data, optionalMessage) {
    try {
      const response = yield this.client.post(
        `/api/v1/shares/${this.id}/invite`,
        {
          users: data,
          project_id: this.projectId,
          optional_message: optionalMessage,
          shareable_type: this.shareableType,
          shareable_id: this.shareableId,
        }
      );

      this.handleConstruction(response.data.share);
      return true;
    } catch (e) {
      toast.error(e.data?.message);
      return false;
    }
  }

  @flow
  *save() {
    try {
      const response = yield this.client.put(
        `/api/v1/shares/${this.id}`,
        this.formDataParams()
      );

      this.handleConstruction(response.data.share);
      return true;
    } catch (e) {
      toast.error(e.data?.message);
      return false;
    }
  }

  @flow
  *destroyOnServer() {
    try {
      yield this.client.delete(`/api/v1/shares/${this.id}`, {
        params: {
          shareable_id: this.shareableId,
          shareable_type: this.shareableType,
        },
      });

      return true;
    } catch (e) {
      toast.error(e.data?.message);

      return false;
    }
  }

  @flow
  *resendInvitations(users) {
    try {
      const response = yield this.client.post(
        `/api/v1/shares/${this.id}/resend`,
        {
          user_ids: users,
          shareable_id: this.shareableId,
          shareable_type: this.shareableType,
        }
      );

      this.handleConstruction(response.data.share);
      toast.success('Invitations have been resent.');
      return true;
    } catch (e) {
      toast.error(e.data?.message);
      return false;
    }
  }
}

export default Share;
