import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

export const toSentence = arr => {
  if (isEmpty(arr)) return '-';
  if (arr.length > 1)
    return `${arr.slice(0, arr.length - 1).join(', ')}, and ${arr.slice(-1)}`;

  return arr[0];
};

export const amountFormatter = number => {
  const unitlist = ['', 'K', 'M', 'G'];
  let num = number || 0;

  const sign = Math.sign(num);
  let unit = 0;
  while (Math.abs(num) > 1000) {
    unit += 1;
    num = Math.floor(Math.abs(num) / 100) / 10;
  }

  return sign * num + unitlist[unit];
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const minEditorToolbar = [
  ['bold', 'italic', 'underline'], // ['strike'] toggled buttons
  ['emoji'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

export const basicEditorToolbar = [
  ['bold', 'italic', 'underline'], // ['strike'] toggled buttons
  ['link', 'image'],
  ['emoji'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  // ['clean'],
];

export const commentEditorToolbar = [
  ['bold', 'italic', 'underline'],
  [{ font: [] }],
  ['emoji'],
  ['link', 'image'],
];
