import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import LayoutBody from './_layout_body';

const PublicLayout = ({ children }) => (
  <LayoutBody forcePublic>{children}</LayoutBody>
);

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default observer(PublicLayout);
