import { makeAutoObservable } from 'mobx';

import AppStore from './stores/app_store';
import UserStore from './stores/user_store';
import ProjectStore from './stores/helpable_store';
import ProjectHelperStore from './stores/project_helper_store';
import AskStore from './stores/ask_store';
import AnnouncementStore from './stores/announcement_store';
import WalletEntryStore from './stores/wallet_entry_store';
import ImpersonateUserStore from './stores/impersonate_user_store';
import HubStore from './stores/hub_store';

export default class RootStore {
  constructor() {
    this.appStore = new AppStore(this);
    this.userStore = new UserStore(this);
    this.projectStore = new ProjectStore(this);
    this.projectHelperStore = new ProjectHelperStore(this);
    this.askStore = new AskStore(this);
    this.walletEntryStore = new WalletEntryStore(this);
    this.impersonateUserStore = new ImpersonateUserStore(this);
    this.announcementStore = new AnnouncementStore(this);
    this.hubStore = new HubStore(this);

    makeAutoObservable(this);
  }
}
