import React, { useState } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty, map, tail } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import t from '../../t';
import useStore from '../../data/store';
import GiveBonus from '../asks/_give_bonus';

const HelperActions = ({ record }) => {
  const { userStore } = useStore();
  const actions = [];
  const nextBestActions = [];

  const showBonus =
    record.canBeRemoved && record.userId !== userStore.currentUser.id;

  const removeHelper = helper => {
    confirmAlert({
      title: 'Confirm Removal of Helper',
      message: 'This helper will be removed from the project. Are you sure?',
      buttons: [
        {
          label: 'Remove',
          onClick: () => helper.updateStatus('removed', true),
        },
        { label: 'Cancel' },
      ],
    });
  };

  const comingSoon = () => {
    confirmAlert({
      title: 'Coming Soon',
      message: 'This feature is coming soon.',
      buttons: [{ label: 'OK' }],
    });
  };
  if (showBonus)
    nextBestActions.push({
      label: 'Give Bonus',
      button: (
        <GiveBonus
          type="primary ml-2 btn-xs"
          fromWalletId={record.project.wallet.id}
          toWalletId={record.walletId}
        />
      ),
    });

  if (record.canBeRemoved && record.userId !== userStore.currentUser.id) {
    nextBestActions.push({
      label: 'Remove',
      click: () => {
        removeHelper(record);
      },
    });
  }
  if ((record.isRequested || record.isDeclined) && record.project?.isBuilder) {
    nextBestActions.push({
      label: t('btn_confirm_helper'),
      click: () => {
        record.updateStatus('confirmed');
      },
    });
    nextBestActions.push({
      label: t('btn_reject_helper'),
      color: 'danger',
      click: () => {
        record.updateStatus('declined');
      },
    });
  }

  const makeButton = nba => {
    if (nba.button) {
      return nba.button;
    }
    return (
      <Button
        id="caret"
        color={nba.color || 'primary'}
        className="btn btn-xs btn-block"
        onClick={nba.click}
      >
        {nba.label}
      </Button>
    );
  };
  if (!isEmpty(nextBestActions)) {
    if (nextBestActions.length === 1) {
      actions.push(makeButton(nextBestActions[0]));
    }
    if (nextBestActions.length > 1) {
      actions.push(
        <UncontrolledDropdown size="xs" group>
          {makeButton(nextBestActions[0])}
          <DropdownToggle split color="primary" />
          <DropdownMenu className="btn-xs">
            {map(tail(nextBestActions), (nba, i) => (
              <DropdownItem className="small" key={i} onClick={nba.click}>
                {' '}
                {nba.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }

  return <>{actions}</>;
};
HelperActions.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired,
};

export default observer(HelperActions);
