import { computed, makeObservable, observable } from 'mobx';

import BaseEntity from './base_entity';

class Wallet extends BaseEntity {
  /* eslint-disable */
  @observable reserved;
  @observable pending;
  @observable balance;
  @observable hasPendingSession = false;
  @observable fetchedEntries = false;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
  }
}

export default Wallet;
