import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BasicProjectForm from '../shared/_basic_project_form';
import ProjectFormWrapper from '../shared/_project_form_wrapper';
import t from '../../t';

const About = ({ helpable }) => {
  const handleChange = e => {
    helpable.update({ [e.target.name]: e.target.value, dirty: true });
  };

  const basicForm = (
    <BasicProjectForm handleChange={handleChange} helpable={helpable} />
  );

  return (
    <div className="project-definiton">
      <ProjectFormWrapper
        title={t(`settings.info`)}
        form={basicForm}
        helpable={helpable}
      />
    </div>
  );
};
About.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
};
export default observer(About);
