import React, { useEffect, useState } from 'react';
import { observer, useObserver } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import ReactTimeAgo from 'react-time-ago';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import RichEditor from '../modules/rich_text_editor';
import { commentEditorToolbar } from '../../helpers/shared_helpers';
import Comment from '../../data/entities/comment';
import useStore from '../../data/store';
import { redirectToAuth } from '../../routes/AuthenticatedRoute';

function Comments({ commentable }) {
  const [comment, setComment] = useState(null);
  const { userStore } = useStore();
  // Assuming you have a state variable for comments
  const store = commentable?.commentStore;
  // Inside your component
  useEffect(() => {
    // This will run once when the component mounts
    if (userStore.userSignedIn) {
      commentable.fetchComments().then(() => {});
      setComment(new Comment({}, commentable.commentStore));
    }
  }, []);

  useEffect(() => {
    // This will run whenever the observed value changes
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        // remove active class from all elements
        document
          .querySelectorAll('.comments-section .active-comment')
          .forEach(e => {
            e.classList.remove('active-comment');
          });
        // add active class to the element
        element.classList.add('active-comment');
      }
    }
  }, [store?.fetched]);

  const handleComment = () => {
    comment.create();
  };

  const renderComment = c => (
    <>
      <div className="d-flex small mb-1">
        <b>{c.projectHelper.friendly_name}</b>{' '}
        <span className="mr-1 ml-1"> - </span>
        <ReactTimeAgo date={new Date(c.createdAt)} className="mr-1" />
        <span> : </span>
      </div>
      <div>
        <RichEditor className="lite-editor h-auto" value={c.body} readOnly />
        <div className="d-flex">
          {c.isItMe && (
            <a href="#" className="small" onClick={() => c.destroy()}>
              Delete
            </a>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Card className="comments-section h-auto mt-3">
        <CardHeader>
          <h5>Comments</h5>
        </CardHeader>
        {!userStore.userSignedIn ? (
          <CardBody className="text-center">
            <a href="#" onClick={redirectToAuth}>
              Sign In
            </a>{' '}
            to comment
          </CardBody>
        ) : (
          !isEmpty(store) && (
            <>
              <CardBody>
                <RichEditor
                  placeholder="Add a comment"
                  onChange={m => comment.update({ body: m })}
                  value={comment?.body}
                  toolbar={commentEditorToolbar}
                />
                <Button className="btn-xs" onClick={handleComment}>
                  Add Comment
                </Button>
              </CardBody>
              <CardBody>
                {store.fetching ? (
                  <div>Fetching Comments...Please wait</div>
                ) : (
                  <div className="">
                    {store.sortedComments.map((c, i) => (
                      <>
                        <div
                          key={i}
                          id={c.id}
                          className="comment mb-4 pb-3 border-bottom"
                        >
                          {renderComment(c)}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </CardBody>
            </>
          )
        )}
      </Card>
    </>
  );
}

Comments.propTypes = {
  commentable: PropTypes.object.isRequired,
};

export default observer(Comments);
